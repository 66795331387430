import React, { Component } from "react"
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import QuestionCard from './question_card.component';
import _t from "../../services/i18n.service";
import stairs_img from '../../assets/stairs.jpg';

const required = value => {
    if (!value || value.length === 0) {
      return (
        <div className="alert alert-danger" role="alert">
          {_t('field_required')}
        </div>
      );
    }
  };

class Question extends Component {

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

        this.state = {
            level: -1,

            successful: false,
            message: '',
        }
    }

    onChange(e) {
        const level = parseInt(e.target.value)
        this.setState({
          level: level
        });
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({
          message: "",
          successful: false
        });
        this.form.validateAll();
        this.props.onSubmit(this.state.level, this.props.question.Question_id);
    }

 
    render() {
        const answer_options = window.$settings.question_types[0].answer_options;

        return (
            <div>
                <div className="center-form">
                    <div>
                        <QuestionCard question={this.props.question} header={true}/>
                        
                        <Form
                            onSubmit={this.onSubmit}
                            ref={c => {
                                this.form = c;
                            }}
                        >
                            <div className="row mt-5">
                                <div className='col-4 d-flex justify-content-end'>
                                    {!this.state.successful && (
                                    <div className="d-flex flex-column">                                
                                        {Object.keys(answer_options).reverse().map((k, i) => 
                                            <div  className="p-1" key={i}>
                                            <input
                                            key={i}
                                            type="radio"
                                            id={k}
                                            name="level"
                                            value={k}
                                            onChange={this.onChange}
                                            validations={[required]}
                                            /> {answer_options[k]}
                                            </div>
                                        )}
                                    </div>
                                    )}
                                </div>
                                <div className='col-8  d-flex justify-content-start'>
                                    <img src={stairs_img} width="200" alt="stairs"/>
                                </div>
                            </div>

                            <div className="form-group mt-5 row">
                                <div className="col-6 text-center">
                                    <button className="btn btn-secondary btn-block" onClick={this.props.onClose}>{_t('close')}</button>
                                </div>
                                 <div className="col-6 text-center">
                                    <button className="btn btn-success btn-block" onClick={this.onSubmit}>{_t('submit')}</button>
                                </div>
                            </div>
      
                            
                            {this.state.message && (
                            <div className="form-group">
                                <div
                                className={
                                    this.state.successful
                                    ? "alert alert-success"
                                    : "alert alert-danger"
                                }
                                role="alert"
                                >
                                {this.state.message}
                                </div>
                            </div>
                            )}
                            <CheckButton
                                style={{ display: "none" }}
                                ref={c => {
                                this.checkBtn = c;
                                }}
                            />
                        </Form>
                    </div>
                </div>
            </div>
        );    
    }
} 

export default Question;
import React, { Component } from "react";
import _t from "../../services/i18n.service";
import Question from "./question.component";
import QuestionCard from "./question_card.component";
import QuestionHistory from "./question_history.component";
import ClassroomCard from "./classroom_card.component";
import BackendService from "../../services/backend.service";
import { Message, showMsg } from "../message.component";

class Classroom extends Component {
  constructor(props) {
    super(props);
    this.onEdit = this.onEdit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setMsg = this.setMsg.bind(this);
    this.getClosedQuestions = this.getClosedQuestions.bind(this);

    this.state = {
      show: "classroom",
      history: false,
      question: null,
      message: { show: false },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }

  setMsg(message) {
    this.setState({ message: message });
  }

  onEdit(q) {
    this.setState({
      show: "question",
      question: q,
    });
  }

  onSubmit(option, question_id) {
    let promise =
      this.state.question.response === -1
        ? BackendService.newAnswer(option, question_id)
        : BackendService.updateAnswer(option, question_id);

    promise
      .then((res) => {
        showMsg(_t("answer_recorded"), "success", this.setMsg);
        setTimeout(this.props.refresh, 2000);
      })
      .catch((err) => {
        showMsg(_t("operation_failed"), "danger", this.setMsg);
        setTimeout(this.props.refresh, 2000);
      });
  }

  getClosedQuestions() {
    const answered_questions = this.props.classroom.questions.filter(
      (q) => q.state === "answered"
    );
    let closed_questions = [];
    answered_questions.forEach(aq => {
      const i = closed_questions.findIndex(cq => cq.title === aq.title);
      if (i === -1) {
        const question = {...aq};
        question.response = {};
        question.response[aq.pre_post] = aq.response;
        closed_questions.push(question);
      } else {
        closed_questions[i].response[aq.pre_post] = aq.response;
      }
    });
    return closed_questions;
  }

  render() {
    const classroom = this.props.classroom;
    const pending_questions = classroom.questions.filter(
      (q) => q.state === "pending"
    );
    const editable_questions = classroom.questions.filter(
      (q) => q.state === "editable"
    );
    const closed_questions = this.getClosedQuestions();
    
    return (
      <div>
        {this.state.message.show && (
          <Message
            text={this.state.message.text}
            type={this.state.message.type}
          />
        )}
        {this.state.show === "classroom" && (
          <div>
            <ClassroomCard
              header={true}
              classroom={classroom}
              history={this.state.history}
              toggleHistory={() => {
                this.setState({ history: !this.state.history });
              }}
            />
            <h5 className="mt-2">{_t("questions")}:</h5>

            {!this.state.history ? ( // open questions
              (editable_questions.length === 0 &&
                pending_questions.length === 0 && (
                  <p>{_t("no_questions")}</p>
                )) || (
                <>
                  {editable_questions.length > 0 &&
                    editable_questions.map((q, i) => (
                      <QuestionCard
                        key={i}
                        question={q}
                        onEdit={this.onEdit}
                        header={false}
                      />
                    ))}

                  {pending_questions.length > 0 &&
                    pending_questions.map((q, i) => (
                      <QuestionCard
                        key={i}
                        question={q}
                        onEdit={this.onEdit}
                        header={false}
                      />
                    ))}
                </>
              )
            ) : closed_questions.length > 0 ? ( // closed questions
              closed_questions.map((q, i) => (
                <QuestionHistory key={i} question={q} />
              ))
            ) : (
              <p>{_t("no_questions")}</p>
            )}
          </div>
        )}
        {this.state.show === "question" && (
          <Question
            onSubmit={this.onSubmit}
            onClose={() => {
              this.setState({ show: "classroom" });
            }}
            question={this.state.question}
          />
        )}
      </div>
    );
  }
}

export default Classroom;

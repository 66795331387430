import axios from "axios";
import config from "../config";

class AuthService {

  login(email, password) {
    return axios.post(config.API_URL + "/login", {
      email: email,
      password: password
    })
    .then(res => {
      let user_data = {
        email: email,
        role_id: res.data.role_id,
        role: res.data.role_id === 1 ? 'ROLE_PROFESSOR' : 'ROLE_STUDENT',
        user_id: res.data.user_id,
        jwt: res.data.jwt,
      }; 
      localStorage.setItem('user', JSON.stringify(user_data));
      return user_data;
    });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(email, password, role) {
    return axios.post(config.API_URL + "/user/create", {
      email: email,
      password: password,
      RoleId: role === 'ROLE_PROFESSOR' ? 1 : 2
    });
  }

  getCurrentUser() {
    var userJSON = localStorage.getItem('user');
    userJSON = userJSON === "undefined" || !userJSON ? '{"email": null, "role": null}' : userJSON;
    const user = JSON.parse(userJSON);
    return user;
  }
}

export default new AuthService();
const msg = {
  es: {
    professor: "profesor/a",
    student: "estudiante",
    link_language: "Idioma",
    link_login: "Entrar",
    link_register: "Registrarse",
    link_logout: "Salir",
    password: "Contraseña",
    enter_email: "Introduzca su email",
    enter_password: "Establezca una contraseña",
    enter_curr_password: "Introduzca contraseña actual",
    enter_new_password: "Introduzca nueva contraseña",
    invalid_email: "El email introducido no es válido",
    invalid_password: "La contraseña introducida no es válida",
    password_instructions: "La contraseña debe tener entre 6 y 40 caracteres",
    field_required: "Este campo es obligatorio",
    new_account_title: "Nueva cuenta creada",
    new_account_text:
      "Por favor, revise su correo electrónico para confirmar la cuenta.",
    close: "Cerrar",
    classrooms_title: "Clases",
    stats_title: "Tus estadísticas",
    add: "Añadir",
    phase: "Fase",
    questions: "Preguntas",
    title: "Título",
    new_classroom_title: "Nueva clase",
    cancel: "Cancelar",
    create: "Crear",
    creation_failed: "No se pudo crear el elemento",
    classroom_created: "La clase se ha creado",
    confirm: "Confirmación",
    confirm_delete: "¿Seguro que quiere borrar?",
    confirm_delete_classroom:
      "Las preguntas de la clase siguiente también serán eliminadas: ",
    confirm_delete_question:
      "La siguiente pregunta va a ser eliminada (incluyendo sus respuestas): ",
    confirm_activation: "¿Seguro que quiere activar los recordatorios?",
    confirm_activate_reminders:
      'Al activar recordatorios se enviarán durante una semana __frequency__ notificaciones al estudiantado matriculado en esta clase.',
    confirm_deactivation: "¿Seguro que quiere desactivar los recordatorios?",
    confirm_deactivate_reminders:
        'Desactivar recordatorios cancelará las notificaciones programadas que aún no hayan sido enviadas al estudiantado.',
    reminders_activated: "Recordatorios activados",
    reminders_disabled: "Recordatorios desactivados",
    reminders_not_changed: "Los cambios no se pudieron completar.",
    no_reminder_without_enrolments: "No se activaron los recordatorios al no haber alumnos matriculados con notificaciones activadas.",
    ok: "Ok",
    question_deleted: "Pregunta eliminada",
    new_question_title: "Nueva pregunta",
    none: "Ninguno",
    "Once per day": "Una vez al día",
    "Once per week": "Una vez a la semana",
    "Once per month": "Una vez al mes",
    choose_question: "Elige pregunta",
    question_type_1: "Auto-control",
    question_type_2: "Conocer tu valía",
    question_type_3: "Fijar metas",
    question_created: "La pregunta se ha añadido",
    new_classroom_id: "ID de nueva clase",
    select_previous: "Seleccionar clase ya suscrita",
    join: "Unirse",
    submit: "Enviar",
    edit: "editar",
    pending: "pendiente",
    answered: "contestada",
    unanswered: "no contestada",
    delete: "Eliminar",
    delete_classroom: "Eliminar clase",
    delete_user: "Eliminar cuenta",
    del_user_instructions:
      "Toda tu información y tu cuenta serán eliminados. Por favor, introduce tu contraseña para confirmar la operación.",
    user_deleted: "La cuenta ha sido eliminada. Abandonando sesión...",
    user_purged: "La información ha sido eliminada.",
    purge_user_instructions:
      "Toda tu información será eliminada. Por favor, introduce tu contraseña para confirmar la operación.",
    aswer_recorded: "Su respuesta ha quedado registrada.",
    user_registered: "La cuenta ha sido creada. Puede iniciar sesión.",
    user_not_registered: "La cuenta no ha podido ser creada.",
    change_password: "Cambiar contraseña",
    password_changed: "La contraseña se ha actualizado. La sesión debe reiniciarse.",
    error_400: "Faltan parámetros: email, contraseña o contraseña anterior.",
    error_401: "Cambio no autorizado: email o contraseña inválidos.",
    error_500: "La contraseña no se ha actualizado debido a un error del servidor.",
    login_failed: "No se ha podido iniciar sesión. Por favor, revise sus datos de acceso.",
    from: "Desde:",
    to: "Hasta:",
    unable_to_get_data: "No se ha podido descargar la información.",
    reminder: "Recordatorios",
    operation_failed: "No se ha podido completar la operación. Inténtelo de nuevo más tarde.",
    classroom_deleted: "La clase ha sido eliminada.",
    email_already_exists: "Ya existe una cuenta con ese correo electrónico.",
    description: "Descripción",
    deadline: "Fecha límite",
    deadline_posterior: "Debe indicarse una fecha posterior a hoy",
    session_expired: "La sesión ha expirado. Debe volver a iniciarla.",
    classroom_not_found: "No se ha encontrado ninguna clase con el código introducido.",
    no_enrolments: "No se ha unido a ninguna clase.",
    already_enroled: "Ya está registrado en esta clase.",
    no_questions: "Todavía no has activado ninguna pregunta para esta clase.",
    answer_recorded: "Respuesta registrada.",
    classroom_cannot_be_deleted: "La clase no se ha podido eliminar. Puede que haya estudiantes ya registrado/as.",
    merit_time: "Número de minutos para cambiar respuesta",
    user_data_deleted: "Todas tus suscripciones y respuestas se han eliminado.",
    remove_user_data: "Eliminar suscripciones y respuestas",
    created_on: "Inicio",
    history: "Histórico",
    response: "Respuesta",
    no_response: "Sin respuesta",
    editable_until: "editable hasta",
    no_question_types: "Todas las preguntas posibles ya se han añadido a esta clase.",
    new_question_notification: "Se ha añadido una nueva pregunta a la clase siguiente:",
    classroom_active_tip: "La clase está activada. Haga clic para inactivarla.",
    classroom_inactive_tip: "La clase está inactiva. Haga clic para activarla.",
    reminders_enabled_tip: "Los recordatorios están activados.",
    reminders_disabled_tip: "Los recordatorios están desactivados. Haga clic para activarlos.",
    reminder_headings: "Saludos desde la clase",
    reminder_subtitle: "Acuérdate de trabajar hoy en el desarrollo de tus habilidades.",
    enable_push_alert: '¡Las notificaciones deben ser permitidas!',
    minutes: "minutos",
    reset_password_msg: "Se ha enviado un enlace de restablecimiento de contraseña a su correo electrónico.",
    reset_password: "Restablecer contraseña",
    no_notifications_warning: "No recibirá notificaciones de los profesores"

  },
  en: {
    professor: "professor",
    student: "student",
    link_language: "Language",
    link_login: "Login",
    link_register: "Sign Up",
    link_logout: "Logout",
    password: "Password",
    enter_email: "Enter valid email",
    enter_password: "Enter a password",
    enter_curr_password: "Enter current password",
    enter_new_password: "Enter new password",
    invalid_email: "This is not a valid email",
    invalid_password: "Invalid password",
    password_instructions: "The password must be between 6 and 40 characters",
    field_required: "This field is required",
    new_account_title: "New account created",
    new_account_text: "Please, check your email to confirm registration.",
    close: "Close",
    classrooms_title: "Classrooms",
    stats_title: "Your stats",
    add: "Add",
    phase: "Phase",
    questions: "Questions",
    title: "Title",
    new_classroom_title: "New classroom",
    cancel: "Cancel",
    create: "Create",
    creation_failed: "Could not create new item",
    classroom_created: "Classroom created",
    confirm: "Confirmation required",
    confirm_delete: "Are you sure you want to remove?",
    confirm_delete_classroom:
      "Questions on this following classroom will also be erased: ",
    confirm_delete_question:
      "Following question will be removed (answers will be lost): ",
    confirm_activation: "Are you sure you want to activate reminders?",
    confirm_activate_reminders:
      'Enabling reminders will send __frequency__ notifications to students currently enrolled in this classroom in the next week.',
    confirm_deactivation: "Are you sure you want to disable reminders?",
    confirm_deactivate_reminders:
        'Disabling reminders will cancel scheduled notifications that have not yet been sent to students.',
    reminders_activated: "Reminders activated",
    reminders_disabled: "Reminders disabled",
    reminders_not_changed: "Reminders could not be changed",
    no_reminder_without_enrolments: "Reminders were not triggered as there were no students enrolled or with notifications enabled.",
    ok: "Ok",
    question_deleted: "Question deleted",
    new_question_title: "New question",
    none: "None",
    "Once per day": "Once per day",
    "Once per week": "Once per week",
    "Once per month": "Once per month",
    choose_question: "Choose question",
    question_type_1: "Self-regulation",
    question_type_2: "Knowing your values",
    question_type_3: "Setting goals",
    question_created: "The question has been added",
    new_classroom_id: "New classroom ID",
    select_previous: "Select joined classroom",
    join: "Join",
    submit: "Submit",
    edit: "edit",
    pending: "pending",
    answered: "answered",
    unanswered: "unanswered",
    delete: "Delete",
    delete_classroom: "Delete classroom",
    delete_user: "Remove account",
    del_user_instructions:
      "All your data and account will be erased. Please, enter your password to confirm operation.",
    user_deleted: "The account has been removed. Closing session...",
    user_purged: "Information has been purged",
    purge_user_instructions:
      "All your information will be purged. Please enter your password to confirm the operation",
    aswer_recorded: "Your answer has been registered.",
    user_registered: "New account created. You can open a session.",
    user_not_registered: "Unable to create new account.",
    change_password: "Change password",
    password_changed: "Password updated. Session must be restarted.",
    error_400: "Missing parameters: email, password or old password.",
    error_401: "Unauthorized: Invalid email or password.",
    error_500: "Internal Server Error: Unable to change password.",
    login_failed: "Login failed. Please, verify your connection and access credentials.",
    from: "From:",
    to: "To:",
    unable_to_get_data: "Unable to retrieve data from server.",
    reminder: "Reminders",
    operation_failed: "Unable to complete action. Please, try again later.",
    classroom_deleted: "Classroom deleted.",
    email_already_exists: "Email address already exists.",
    description: "Description",
    deadline: "Deadline",
    deadline_posterior: "Deadline must be set in the future.",
    session_expired: "The session has expired. You must log in again.",
    classroom_not_found: "No classroom found with this code.",
    no_enrolments: "You have not joined any classroom.",
    already_enroled: "You have already joined this classroom.",
    no_questions: "You haven't activated any questions yet for this class.",
    answer_recorded: "Answer registered.",
    classroom_cannot_be_deleted: "Classroom has not been deleted. There may be students already registered.",
    merit_time: "Number of minutes to change answers",
    user_data_deleted: "All your enrolments and answers have been removed.",
    remove_user_data: "Erase enrolments and answers",
    created_on: "Started on",
    history: "History",
    response: "Response",
    no_response: "Unanswered",
    editable_until: "editable until",
    no_question_types: "All possible questions have already been added to this classroom.",
    new_question_notification: "A new question has been added to this classroom:",
    classroom_active_tip: "Classroom is activated. Click to make inactive.",
    classroom_inactive_tip: "Classroom is inactive. Click to activate.",
    reminders_enabled_tip: "Reminders are active.",
    reminders_disabled_tip: "Reminders are disabled. Click to enable.",
    reminder_headings: "Hello from classroom",
    reminder_subtitle: "Remember to work on your skill building today.",    
    enable_push_alert: "Push Notifications must be enabled!",
    minutes: "minutes",
    reset_password_msg: "Password reset link has been sent to your email.",
    reset_password: "Reset password",
    no_notifications_warning: "You will not receive any notifications from the Teachers"
  },
  gr: {
    professor: "καθηγητής",
    student: "μαθητής",
    link_language: "Γλώσσα",
    link_login: "Σύνδεση",
    link_register: "Εγγραφή",
    link_logout: "Αποσύνδεση",
    password: "Κωδικός",
    enter_email: "Εισαγάγετε έγκυρο email",
    enter_password: "Ορισμός κωδικού πρόσβασης",
    enter_curr_password: "Enter current password",
    enter_new_password: "Πληκτρολογείστε τον υπάρχοντα κωδικό",
    invalid_email: "This is not a valid email",
    invalid_password: "Μη έγκυρο email",
    password_instructions: "Ο κωδικός πρόσβασης πρέπει να είναι από 6 έως 40 χαρακτήρες",
    field_required: "Αυτό το πεδίο είναι υποχρεωτικό",
    new_account_title: "Δημιουργήθηκε νέος λογαριασμός",
    new_account_text: "Ελέγξτε το email σας για να επιβεβαιώσετε την εγγραφή.",
    close: "Κλείσιμο",
    classrooms_title: "Αίθουσες διδασκαλίας",
    stats_title: "Τα στατιστικά σας",
    add: "Προσθήκη",
    phase: "Φάση",
    questions: "Ερωτήσεις",
    title: "Τίτλος",
    new_classroom_title: "Νέα αίθουσα διδασκαλίας",
    cancel: "Ακύρωση",
    create: "Δημιουργία",
    creation_failed: "Δεν ήταν δυνατή η δημιουργία νέου στοιχείου",
    classroom_created: "Δημιουργήθηκε η αίθουσα διδασκαλίας",
    confirm: "Απαιτείται επιβεβαίωση",
    confirm_delete: "Είστε βέβαιοι ότι θέλετε να καταργήσετε?",
    confirm_delete_classroom:
      "Οι ερωτήσεις σχετικά με αυτήν την τάξη που ακολουθεί θα διαγραφούν επίσης: ",
    confirm_delete_question:
      "Η ακόλουθη ερώτηση θα αφαιρεθεί (οι απαντήσεις θα χαθούν): ",
    confirm_activation: "Ενεργοποίηση υπενθυμίσεων?",
    confirm_activate_reminders:
      'Η ενεργοποίηση των υπενθυμίσεων θα στείλει __frequency__ ειδοποιήσεις στους μαθητές που είναι εγγεγραμμένοι σε αυτή την τάξη την επόμενη εβδομάδα.',
    confirm_deactivation: "Είστε σίγουροι ότι θέλετε να απενεργοποιήσετε τις υπενθυμίσεις;",
    confirm_deactivate_reminders:
        'Η απενεργοποίηση των υπενθυμίσεων θα ακυρώσει τις προγραμματισμένες ειδοποιήσεις που δεν έχουν ακόμη αποσταλεί στους μαθητές.',
    reminders_activated: "Ενεργοποίηση υπενθυμίσεων",
    reminders_disabled: "Απενεργοποιημένες υπενθυμίσειςx",
    reminder_headings: "Γεια σας από την αίθουσα διδασκαλίας",
    reminder_subtitle: "Θυμηθείτε να δουλέψετε στην ανάπτυξη των δεξιοτήτων σας σήμερα.",
    ok: "Ok",
    question_deleted: "Η ερώτηση διαγράφηκε",
    new_question_title: "Νέα ερώτηση",
    none: "None",
    "Once per day": "Μία φορά την ημέρα",
    "Once per week": "Μία φορά την εβδομάδα",
    "Once per month": "Μία φορά τον μήνα",
    choose_question: "Διάλεξε ερώτηση",
    question_type_1: "Self-regulation",
    question_type_2: "Knowing your values",
    question_type_3: "Setting goals",
    question_created: "The question has been added",
    new_classroom_id: "Νέα ταυτότητα τάξης",
    select_previous: "Επιλέξτε προηγούμενο",
    join: "συμμετοχή",
    submit: "υποβολή",
    edit: "επεξεργασια",
    pending: "εκκρεμής",
    answered: "απαντήθηκε",
    unanswered: "δεν απαντήθηκε",
    delete: "Διαγραφή",
    delete_classroom: "Διαγραφή αίθουσων διδασκαλίας",
    delete_user: "Διαγραφή λογαριασμού",
    del_user_instructions:
      "Όλα τα δεδομένα και ο λογαριασμός σας θα διαγραφούν. Εισαγάγετε τον κωδικό πρόσβασής σας για να επιβεβαιώσετε τη λειτουργία.",
    user_deleted: "Ο λογαριασμός έχει αφαιρεθεί. Κλείσιμο συνεδρίας...",
    user_purged: "Οι πληροφορίες έχουν διαγραφεί",
    purge_user_instructions:
      "Όλες οι πληροφορίες σας θα διαγραφούν. Παρακαλώ εισάγετε τον κωδικό πρόσβασής σας για να επιβεβαιώσετε τη λειτουργία",
    aswer_recorded: "Η απάντησή σας έχει καταχωρηθεί.",
    user_registered: "Δημιουργήθηκε νέος λογαριασμός. Μπορείτε να ανοίξετε μια συνεδρία.",
    user_not_registered: "Δεν είναι δυνατή η δημιουργία νέου λογαριασμού.",
    change_password: "Αλλαξε κωδικό",
    password_changed: "Ο κωδικός ενημερώθηκε. Η συνεδρία πρέπει να επανεκκινηθεί.",
    error_400: "Λείπουν παράμετροι: email, κωδικός πρόσβασης ή παλιός κωδικός πρόσβασης.",
    error_401: "Μη εξουσιοδοτημένο: Μη έγκυρο email ή κωδικός πρόσβασης.",
    error_500: "Εσωτερικό σφάλμα διακομιστή: Δεν είναι δυνατή η αλλαγή κωδικού πρόσβασης.",
    login_failed: "Η σύνδεση απέτυχε. Παρακαλούμε, επαληθεύστε τη σύνδεσή σας και αποκτήστε πρόσβαση στα διαπιστευτήρια.",
    from: "From:",
    to: "To:",
    // TRANSLATED WITH DEEPL:
    unable_to_get_data: "Αδυναμία λήψης πληροφοριών",
    reminder: "Υπενθυμίσεις",
    operation_failed: "Η λειτουργία δεν μπόρεσε να ολοκληρωθεί. Προσπαθήστε ξανά αργότερα.",
    classroom_deleted: "αίθουσα διδασκαλίας διαγράφηκε.",
    email_already_exists: "Η διεύθυνση ηλεκτρονικού ταχυδρομείου υπάρχει ήδη",
    description: "περιγραφή",
    deadline: "προθεσμία",
    deadline_posterior: "Πρέπει να αναφέρεται ημερομηνία μετά τη σημερινή",
    session_expired: "Η συνεδρία σας έχει λήξει. Πρέπει να συνδεθείτε ξανά.",
    classroom_not_found: "Δεν βρέθηκε αίθουσα διδασκαλίας με αυτόν τον κωδικό.",
    no_enrolments: "Δεν έχετε ενταχθεί σε καμία τάξη.",
    already_enroled: "Έχετε ήδη ενταχθεί σε αυτή την τάξη.",
    no_questions: "Δεν έχετε ενεργοποιήσει ακόμα καμία ερώτηση για αυτή την κατηγορία.",
    answer_recorded: "Εγγεγραμμένη απάντηση.",
    classroom_cannot_be_deleted: "Η κλάση δεν μπόρεσε να διαγραφεί. Μπορεί να υπάρχουν ήδη εγγεγραμμένοι μαθητές.",
    merit_time: "Αριθμός λεπτών για την αλλαγή των απαντήσεων",
    user_data_deleted: "Όλες οι εγγραφές και οι απαντήσεις σας έχουν αφαιρεθεί.",
    remove_user_data: "Κατάργηση συνδρομών και απαντήσεων",
    created_on: "έναρξη",
    history: "Ιστορία",
    response: "Απάντηση",
    no_response: "Καμία απάντηση",
    editable_until: "επεξεργάσιμη έως",
    no_question_types: "Όλες οι πιθανές ερωτήσεις έχουν ήδη προστεθεί σε αυτή την τάξη.",
    new_question_notification: "Μια νέα ερώτηση έχει προστεθεί στην επόμενη τάξη:",
    classroom_active_tip: "Η τάξη είναι ενεργοποιημένη. Κάντε κλικ για να την κάνετε ανενεργή.",
    classroom_inactive_tip: "Η τάξη είναι ανενεργή. Κάντε κλικ για να την ενεργοποιήσετε.",
    reminders_enabled_tip: "οι υπενθυμίσεις είναι ενεργές.",
    reminders_disabled_tip: "Οι υπενθυμίσεις είναι απενεργοποιημένες. Κάντε κλικ για να τις ενεργοποιήσετε.",
    no_reminder_without_enrolments: "Οι υπενθυμίσεις δεν ενεργοποιήθηκαν καθώς δεν υπήρχαν εγγεγραμμένοι μαθητές με ενεργοποιημένες ειδοποιήσεις.",
    enable_push_alert: "Οι ειδοποιήσεις Push πρέπει να είναι ενεργοποιημένες!",
    minutes: "λεπτά",
    reset_password_msg: "Ο σύνδεσμος επαναφοράς κωδικού πρόσβασης έχει σταλεί στο email σας.",
    reset_password: "επαναφορά κωδικού πρόσβασης",
    no_notifications_warning: "Δεν θα λαμβάνετε ειδοποιήσεις από τους δασκάλους"
  },
  de: {
    professor: "professor",
    student: "student",
    link_language: "Taal",
    link_login: "Login",
    link_register: "Aanmelden",
    link_logout: "Uitloggen",
    password: "Wachtwoord",
    enter_email: "Voer een geldig e-mailadres in",
    enter_password: "Voer een wachtwoord in",
    enter_curr_password: "Huidig wachtwoord invoeren",
    enter_new_password: "Nieuw wachtwoord invoeren",
    invalid_email: "Dit is geen geldig e-mailadres",
    invalid_password: "Ongeldig wachtwoord",
    password_instructions: "Het wachtwoord moet tussen de 6 en 40 karakters zijn",
    field_required: "Dit veld is verplicht",
    new_account_title: "Nieuw account aangemaakt",
    new_account_text: "Controleer alstublieft uw e-mail om de registratie te bevestigen.",
    close: "Sluiten",
    classroom_title: "Klaslokalen",
    stats_title: "Uw statistieken",
    add: "Toevoegen",
    phase: "Fase",
    questions: "Vragen",
    title: "Titel",
    new_classroom_title: "Nieuw klaslokaal",
    cancel: "Annuleren",
    create: "Create",
    creation_failed: "Kon geen nieuw item aanmaken",
    classroom_created: "Klaslokaal aangemaakt",
    confirm: "Bevestiging vereist",
    confirm_delete: "Weet u zeker dat u wilt verwijderen?",
    confirm_delete_classroom:
      "Vragen over dit volgende klaslokaal zullen ook worden gewist: ",
    confirm_delete_question:
      "Volgende vraag zal worden verwijderd (antwoorden gaan verloren): ",
    confirm_activation: "Herinneringen activeren?",
    confirm_activate_reminders:
      'Herinneringen inschakelen stuurt de komende week __frequency__ meldingen naar studenten die momenteel in dit klaslokaal staan ingeschreven.',
    confirm_deactivate_reminders:
      'Door reminders uit te schakelen, worden geplande meldingen die nog niet naar studenten zijn verzonden, geannuleerd.',
    reminders_activated: "Herinneringen geactiveerd",
    reminders_disabled: "Weet je zeker dat je herinneringen wilt uitschakelen?",
    reminders_not_activated: "Herinneringen konden niet worden geactiveerd",
    reminder_headings: "Hallo uit de klas",
    reminder_subtitle: "Vergeet niet om vandaag aan je vaardigheden te werken.",
    no_reminder_without_enrolments: "Herinneringen werden niet geactiveerd omdat er geen ingeschreven studenten waren met geactiveerde meldingen.",
    ok: "Ok",
    question_deleted: "Vraag verwijderd",
    new_question_title: "Nieuwe vraag",
    none: "Geen",
    once_per_day: "Eenmaal per dag",
    once_per_week: "Eenmaal per week",
    once_per_month: "Eenmaal per maand",
    choose_question: "Kies vraag",
    question_type_1: "Zelfregulatie",
    question_type_2: "Je waarden kennen",
    question_type_3: "Doelen stellen",
    question_created: "De vraag is toegevoegd",
    new_classroom_id: "ID van het nieuwe klaslokaal",
    select_previous: "Selecteer lid geworden klaslokaal",
    join: "Join",
    submit: "Submit",
    edit: "bewerken",
    pending: "in afwachting van",
    answered: "answered",
    unanswered: "unanswered",
    delete: "Delete",
    delete_classroom: "Verwijder klaslokaal",
    delete_user: "Verwijder account",
    del_user_instructions:
      "Al uw gegevens en account zullen worden gewist. Voer uw wachtwoord in om de bewerking te bevestigen.",
    user_deleted: "Het account is verwijderd. Sessie sluiten...",
    user_purged: "Informatie is gezuiverd",
    purge_user_instructions:
      "Al uw informatie zal worden gezuiverd. Voer uw wachtwoord in om de bewerking te bevestigen",
    aswer_recorded: "Uw antwoord is geregistreerd.",
    user_registered: "Nieuw account aangemaakt. U kunt een sessie openen.", 
    user_not_registered: "Niet in staat om nieuwe account aan te maken.",
    change_password: "Wijzig wachtwoord",
    password_changed: "Wachtwoord bijgewerkt. Sessie moet opnieuw worden opgestart.",
    error_400: "Ontbrekende parameters: e-mail, wachtwoord of oude wachtwoord.",
    error_401: "Niet geautoriseerd: Ongeldige e-mail of wachtwoord.", 
    error_500: "nterne serverfout: Wachtwoord kan niet worden gewijzigd.",
    login_failed: "Inloggen mislukt. Gelieve uw verbinding en toegangsgegevens te verifiëren.",
    from: "Van:",
    to: "Aan:",
    unable_to_get_data: "Kan gegevens niet ophalen van server.",
    reminder: "Herinneringen",
    operation_failed: "Niet in staat om actie te voltooien. Probeer het later nog eens.",
    classroom_deleted: "Klaslokaal verwijderd.",
    email_already_exists: "Emailadres bestaat al.",
    description: "Omschrijving",
    deadline: "Deadline",
    deadline_posterior: "Deadline moet in de toekomst worden gesteld",
    session_expired: "De sessie is verlopen. U moet zich opnieuw aanmelden.",
    classroom_not_found: "Geen klaslokaal gevonden met deze code.",
    no_enrolments: "U bent niet lid geworden van een klaslokaal.",
    already_enroled: "U bent al lid geworden van dit klaslokaal.",
    no_questions: "Je hebt nog geen vragen geactiveerd voor deze klas.",
    answer_recorded: "Antwoord geregistreerd.",
    classroom_cannot_be_deleted: "Klaslokaal is niet verwijderd. Er zijn mogelijk al studenten ingeschreven.",
    medit_time: "Aantal minuten om antwoorden te wijzigen",
    user_data_deleted: "Al uw inschrijvingen en antwoorden zijn verwijderd.",
    remove_user_data: "Inschrijvingen en antwoorden wissen",
    created_on: "Gestart op",
    history: "Geschiedenis",
    response: "Reactie",
    no_response: "Unanswered",
    editable_until: "Bewerkbaar tot",
    no_question_types: "Alle mogelijke vragen zijn al toegevoegd aan deze klas.",
    new_question_notification: "Er is een nieuwe vraag toegevoegd aan dit klaslokaal:",
    classroom_active_tip: "Klaslokaal is geactiveerd. Klik om inactief te maken.",
    classroom_inactive_tip: "Klaslokaal is inactief. Klik om te activeren.",
    reminders_enabled_tip: "Herinneringen zijn actief.",
    reminders_disabled_tip: "Herinneringen zijn uitgeschakeld. Klik om in te schakelen.",
    enable_push_alert: "Push-meldingen moeten ingeschakeld zijn!",
    minutes: "minuten",
    reset_password_msg: "Wachtwoord reset link is verzonden naar uw e-mail.",
    reset_password: "Wachtwoord resetten",
    no_notifications_warning: "U ontvangt geen meldingen van de Leraren"
  },
};

function _t(k) {
  return msg[window.$lang || "en"][k];
}

export default _t;

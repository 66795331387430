import React from "react";
import _t from "../../services/i18n.service";
import { toLocalTimeString } from "../../services/time.service";

function QuestionCard(props) {
  let question = props.question;

  const deadline_date = new Date(question.deadline);
  const deadline = toLocalTimeString(deadline_date);
  const created_on_date = new Date(question.created_on);
  const created_on = toLocalTimeString(created_on_date);

  return (
    <div className="card row mt-4 mx-4" onClick={() => props.header ? false : props.onEdit(question)}>
      <div
        className={
          "card-header " +
          (question.state === "editable"
            ? "bg-primary text-light"
            : question.state === "pending"
              ? "bg-warning"
              : question.state === "answered"
                ? "bg-success text-light"
                : "")
        }
      >
        <div className="row">
          <div className="col-12"><b>{question.title}</b></div>
        </div>
        <div className="row">
          <div className="col-6  d-flex justify-content-start">{question.pre_post}</div>
          <div className="col-6 d-flex justify-content-end">
   
            {question.state === "editable" && (
              <>{_t('edit')} <i className="bi bi-pencil-square ms-1"></i></>
            )}
            {question.state === "pending" && (
              <>{_t('pending')} <i className="bi bi-hourglass-split ms-1"></i></>
            )}
            {question.state === "answered" && (
              <>{_t('answered')} <i className="bi bi-check2-circle ms-1"></i></>
            )}
            {question.state === "unanswered" && (
              <>{_t('unanswered')} <i className="bi bi-x-circle ms-1"></i></>
            )}
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 text-secondary">
            <p>{question.description}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <p className=" justify-content-start">
              ({_t("created_on")})
              <i className="bi bi-calendar-check me-1 ms-1"></i> {created_on}
            </p>
          </div>
          <div className="col-6">
            <p style={{ textAlign: 'end' }}>
              ({_t("deadline")})<i className="bi bi-calendar-x me-1 ms-1"></i>{" "}
              {deadline}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-8 d-flex justify-content-start">
            <span className={
              (question.state === "editable"
              ? "text-primary"
              : question.state === "pending"
              ? "text-warning"
              : question.state === "answered"
              ? "text-success"
              : "")}>
              <b>{question.response !== -1 ? `${_t('response')}: ${question.response}` : _t('no_response')}</b>
              </span>
              <span className="text-secondary ms-2">
                {(question.state === "editable" ? `(${_t("editable_until")} ${toLocalTimeString(question.merit_time)})` : "")}
              </span>
          </div>
          <div className="col-4 d-flex justify-content-end">
            {(question.state === "editable" ||
              question.state === "pending") && !props.header && (
                <i className="bi bi-chevron-right"></i>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuestionCard;
import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
//import AuthService from "../services/fake_auth.service";
import AuthService from "../services/auth.service";
import { isEmail } from "validator";
import { useNavigate } from "react-router-dom";
import _t from "../services/i18n.service";
import Language from "./language.component";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import config from "../config";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        {_t("field_required")}
      </div>
    );
  }
};

const vemail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        {_t("invalid_email")}
      </div>
    );
  }
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.state = {
      email: "",
      password: "",
      loading: false,
      message: "",
    };
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleLogin(e) {
    const { navigate } = this.props;

    e.preventDefault();
    this.setState({
      message: "",
      loading: true,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.email, this.state.password).then(
        (user) => {
          this.props.cb();
          if (user.role === "ROLE_PROFESSOR") {
            navigate(config.BASE_PATH+"/prof", { replace: true });
          } else {
            navigate(config.BASE_PATH+"/stu", { replace: true });
          }
        },
        (err) => {
          const err_msg = err.response.data.message;
          if (err_msg === 'Invalid email/password') {
            this.setState({
              loading: false,
              message: _t('login_failed'),
            });
          } else {
            this.setState({
              loading: false,
              message: err_msg,
            });

          }
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    return (
      <div className="center-form">
        <div>
          <Form
            onSubmit={this.handleLogin}
            ref={(c) => {
              this.form = c;
            }}
          >
            <div className="form-group">
              <div className="form-group mt-2">
                <label htmlFor="email">Email</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChangeEmail}
                  validations={[required, vemail]}
                />
              </div>
              <div className="form-group mt-2">
                <label htmlFor="password">{_t("password")}</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChangePassword}
                  validations={[required]}
                />
              </div>
              <div className="form-group mt-2 text-center">
                <button
                  className="btn btn-success btn-block"
                  disabled={this.state.loading}
                >
                  {this.state.loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span className="ms-1">{_t("link_login")}</span>
                </button>
              </div>
              {this.state.message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {this.state.message}
                  </div>
                </div>
              )}
              <CheckButton
                style={{ display: "none" }}
                ref={(c) => {
                  this.checkBtn = c;
                }}
              />
            </div>
          </Form>
        </div>
        <div className="text-center">
          <LinkContainer to={config.BASE_PATH + "/reset-password"}>
            <Nav.Link>
              <i className="bi bi-key"></i> {_t("reset_password")}
            </Nav.Link>
          </LinkContainer>
        </div>
        <Language onClick={this.props.onChangeLang} />
      </div>
    );
  }
}

// Wrap and export
export default function(props) {
  const navigate = useNavigate();

  return <Login {...props} navigate={navigate} />;
}

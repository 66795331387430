import React from "react"

export function Message (props) {

    return (
        <div className="m-4" data-class="btn-close">
            <div className={"alert alert-" + props.type + " alert-dismissible fade show"} role="alert">
                <p>{props.text}</p>
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        </div>
    );
};

export function showMsg(text, type, setFunc) {
    setFunc({text: text, type: type, show: true});
    setTimeout(() => {
        setFunc({text: '', type: '', show: false});
    }, 5000);
};
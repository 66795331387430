import axios from "axios";
import AuthService from "./auth.service";
import config from "../config";
// import { professor_data, student_data, classroom_data } from "./fake-content";
import _t from "./i18n.service";
// import { toServerTime } from "./time.service";
// import OneSignal from 'react-onesignal';

class BackendService {
  bearerConfig() {
    const user = JSON.parse(localStorage.getItem("user"));
    const config = {
      headers: { Authorization: `Bearer ${user.jwt}` },
    };
    return config;
  }

  updateJWT(res) {
    if (res && res.data && res.data.jwt) {
      const user = {
        ...JSON.parse(localStorage.getItem("user")),
        jwt: res.data.jwt,
      };
      localStorage.setItem("user", JSON.stringify(user));
    }
  }

  changePassword(email, password, new_password) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.API_URL + "/change-password",
          {
            email: email,
            password: password,
            newPassword: new_password,
          },
          this.bearerConfig()
        )
        .then((res) => {
          this.updateJWT(res);
          resolve({ message: _t("password_changed") });
        })
        .catch((err) => {
          reject({ message: _t("error_" + err.response.status) });
        });
    });
  }

  async getSettings() {
    //console.log("Getting settings");

    const settings = await axios
      .get(config.API_URL + "/question-settings")
      .then(res => res.data.settings[0])
      .catch(err => { throw err });

    const options = await axios
      .get(config.API_URL + "/question-options")
      .then(res => res.data.options)
      .catch(err => { throw err });

    return { ...settings, question_types: options };
  }

  getClassrooms() {
    return axios
      .get(config.API_URL + "/classrooms/user", this.bearerConfig())
      .then((res) => {
        let classrooms = res.data.classrooms;
        if (classrooms.length > 0) {
          classrooms = classrooms.sort((a, b) => b.id - a.id); // sort: newests first
        }
        return classrooms;
      })
      .catch((err) => { throw err });
  }

  resetPassword(email) {
    return axios
      .post(config.API_URL + "/forgot-password", { email: email })
      .then(res => res)
      .catch((err) => { throw err });
  }

  // --------------------------------------------------------------------------
  // PROFESSOR API CALLS
  // --------------------------------------------------------------------------

  async getProfessorQuestions(code) {
    return axios
      .get(
        config.API_URL + "/questions/" + code,
        this.bearerConfig()
      )
      .then(async (res) => {
        let questions = res.data.questions;
        if (questions.length > 0) {
          questions = questions.sort((a, b) => b.Question_id - a.Question_id);
          for (let i = 0; i < questions.length; i++) {
            const answers_count = await axios
              .get(config.API_URL + `/answers/count/${questions[i].Question_id}`)
              .then(res => res.data.total_answers[0].count);
            questions[i].answers_count = answers_count;
          }
        }
        return questions;
      })
      .catch((err) => { throw err });
  }

  newClassroom(classroom_info) {
    return axios
      .post(
        config.API_URL + "/classroom/create",
        {
          "title": classroom_info.title,
          "phase": classroom_info.phase,
          "reminder": String(classroom_info.reminder),
          "state": "disabled"
        },
        this.bearerConfig()
      )
      .then(res => res.data)
      .catch(err => { throw err });
  }

  delClassroom(code) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          config.API_URL + "/classroom/delete/" + code,
          this.bearerConfig()
        )
        .then((res) => {
          this.updateJWT(res);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  newQuestion(question_data) {
    return axios
      .post(
        config.API_URL + "/question/create",
        question_data,
        this.bearerConfig()
      )
      .then((res) => res.data)
      .catch((err) => { throw err });
  }

  notifyNewQuestion(classroom) {
    return axios
    .post(
      config.API_URL + "/web-push",
      {
        classroomCode: classroom.code.toUpperCase(),
        headings: _t('new_question_title'),
        subtitle: _t('new_question_notification') + ` ${classroom.title} (${classroom.code.toUpperCase()})`,
        click_url: 'https://' + window.location.host + (window.location.path ? window.location.path : '')
      },
      this.bearerConfig()
    )
    .then(() => classroom)
    .catch(err => err);
  }

  delQuestion(id) {
    return axios
      .delete(
        config.API_URL + `/question/delete/ ${id}`,
        this.bearerConfig()
      )
      .then((res) => {
        this.updateJWT(res);
        return res;
      })
      .catch((err) => { throw err });
  }

  updateClassroomVisibility(code, state) {
    const user = AuthService.getCurrentUser();
    return axios
      .put(config.API_URL + `/classroom/change-state/${user.email}/${code}/${state}`, {}, this.bearerConfig());
  }

  subscriptionsExist(classroom) {
    // Checks if there are students enrolled and subscribed (notifications activated) to that classroom
    return axios
      .get(config.API_URL + `/all-filtered-devices/${classroom.code}`, this.bearerConfig);
  }

  updateClassroomReminderStatus(classroom, reminder_status) {
    // classroomCode, headings, subtitle, campaign, topic, click_url 
    const user = AuthService.getCurrentUser();
    return axios
      .put(config.API_URL + `/classroom/change-reminder-status/${user.email}/${classroom.code}/${reminder_status}`, {}, this.bearerConfig())
      .then(() => axios.post(
        config.API_URL + "/web_push_reminder",
        {
          classroomCode: classroom.code.toUpperCase(),
          headings: _t('reminder_headings') + ` ${classroom.title} (${classroom.code.toUpperCase()})`,
          subtitle: _t('reminder_subtitle'),
          click_url: 'https://' + window.location.host + (window.location.path ? window.location.path : '')
        },
        this.bearerConfig()
      ))
      .catch((err) => { throw err });
  }

  // --------------------------------------------------------------------------
  // STUDENT API CALLS
  // --------------------------------------------------------------------------
 
  getStudentQuestions(code) {
    const user = AuthService.getCurrentUser();
    return axios
      .get(config.API_URL + "/questions/" + code, this.bearerConfig())
      .then(async res => {
        let questions = res.data.questions;
        if (questions.length > 0) {
          questions = questions.sort((a, b) => b.Question_id - a.Question_id);
        }
        for (let i = 0; i < questions.length; i++) {
          let question = questions[i];
          let answer = await axios.get(config.API_URL + "/answer/" + user.email + "/" + question.Question_id, this.bearerConfig());
          if (answer.data.response) {
            question.response = answer.data.response;
          }
            
          if (answer.data.results) {
            const last_response = answer.data.results.pop();
            question.response = last_response.selected_option;
            question.merit_time = new Date (last_response.merit_time);
          }
          const deadline = new Date(question.deadline);
          const answered = question.response !== -1;
          const deadline_passed = new Date() > deadline;

          if (answered) {
            const merit_time_passed = new Date() > question.merit_time;
            question.state = deadline_passed ? "answered" : merit_time_passed ? "answered" : "editable";
          } else {
            question.state = deadline_passed ? "unanswered" : "pending";
          }

          questions[i] = question;
        }
        return questions;
      });
  }

  subscribeToClassroom(code, email) {
    return axios
      .post(
        config.API_URL + "/enrolment/create",
        {
          classroomCode: code.toUpperCase(),
          userEmail: email
        },
        this.bearerConfig()
      )
      .then((res) => res.data.enrolment)
      .catch((err) => {
        if (err.response && err.response.data) {
          throw new Error(err.response.data.message);
        } else {
          //console.log("ERROR", err);
          throw new Error();
        }
      });
  }

  async subscribeToNotifications(classroom_code, user_id) {
    if (!user_id) {
      throw new Error(_t("no_notifications_warning"));
    }
    return axios
      .put(
        config.API_URL + "/device",
        {
          classroomCode: classroom_code,
          player_id: user_id
        },
        this.bearerConfig()
      )
      .then((res) => res)
      .catch((err) => {
        //console.log(err); 
        throw new Error(err.response.data.message)
      });
  }

  unsubscribeFromNotifications(classroom_code, user_id) {
    /*return new Promise((res, rej) => {
      console.log(classroom_code, user_id);
      res();
    });*/

    return axios
      .put(
        config.API_URL + "/device/classroom-unsubscribe",
        {
          classroomCode: classroom_code,
          player_id: user_id
        },
        this.bearerConfig()
      )      
      .then((res) => res)
      .catch((err) => ({ status: err.response.status }));
  }

  delFromNotificationServer(user_id) {
    return axios
      .delete(
        config.API_URL + "/device/delete",
        { 
          data: {
            player_id: user_id
          },
          ...this.bearerConfig()
        },
        
      )
      .then((res) => res);
  }

  newAnswer(option, question_id) {
    const user = AuthService.getCurrentUser();

    return axios
      .post(
        config.API_URL + "/answer/submit",
        {
          UserId: user.user_id,
          QuestionId: question_id,
          selected_option: option,
        },
        this.bearerConfig()
      )
      .then(() => { })
      .catch(err => { throw err });
  }

  updateAnswer(option, question_id) {
    const user = AuthService.getCurrentUser();

    return axios
      .put(
        config.API_URL + `/answer/change/${user.email}/${question_id}/${option}`, 
        {}, this.bearerConfig()
      );
  }

  purgeUser(email, password) {
    return axios
      .delete(config.API_URL + "/purge-data", {
        data: {
          email: email,
          password: password,
        },
        ...this.bearerConfig(),
      })
      .catch(err => {
        if (err.response && err.response.data) {
          if (err.response.data.message === 'Invalid email/password') {
            throw new Error(_t('error_401'));
          }
        }
        throw new Error(_t('operation_failed'));
      });      
  }

  delUser(email, password) {
    return axios
      .delete(config.API_URL + "/user/delete", {
        data: {
          email: email,
          password: password,
        },
        ...this.bearerConfig(),
      })
      .then(() => ({ message: _t("user_deleted") }))
      .catch((err) => {
        if (err.response && err.response.data) {
          if (err.response.data.message === 'Invalid email/password') {
            throw new Error(_t('error_401'));
          }
        }
        throw new Error(_t('operation_failed'));
      });   
  }
}

export default new BackendService();

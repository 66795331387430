import React from "react";
import _t from "../../services/i18n.service";

export default function ClassroomCard(props) {
  let classroom = props.classroom;
  
  return (
    <div className="card mt-4" onClick={() => props.header || classroom.state === "disabled" ? false : props.goToClassroom(classroom)}>
      <div className={"card-header " + (classroom.state === "active" ?  "bg-primary text-light" : "bg-light")}>
        <div className="row">
          <div className="col-6 d-flex justify-content-start">
            {classroom.code.toUpperCase()}
          </div>
          <div className="col-6 d-flex justify-content-end">
            {_t('phase') + " " + classroom.phase}
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-8">
            <h5 className="card-title d-flex justify-content-start">
              <span className="mx-2">
                <i className="bi bi-easel"></i>
              </span>{" "}
              {classroom.title}
            </h5>
          </div>
          <div className="col-4 d-flex justify-content-end">
            {!props.header && (<i className="bi bi-chevron-right"></i>)}
            {props.header && (
              <button
              className={props.history ? "btn btn-secondary" : "btn btn-light"}
              onClick={props.toggleHistory}
              >
                <i className="bi bi-clipboard-check me-1"></i> {_t('history')}
              </button>
              
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import _t from "../../services/i18n.service";
import { toLocalTimeString } from "../../services/time.service";

function QuestionCard(props) {
  let question = props.question;

  const deadline_date = new Date(question.deadline);
  const deadline = toLocalTimeString(deadline_date);
  const created_on_date = new Date(question.created_on);
  const created_on = toLocalTimeString(created_on_date);

  return (
    <div className="card row mt-4 mx-4">
      <div className="card-header">
        <div className="row">
          <div className="col-6"><i className="bi bi-question-circle"></i>{' '}{question.title}</div>
          <div className="col-2 d-flex justify-content-center">{question.pre_post}</div>
          <div className="col-2 d-flex justify-content-end">
            {question.answers_count} <i className="bi bi-person-fill text-secondary ms-1"></i>
          </div>
          <div className="col-2 d-flex justify-content-end">
            <button
              className="bi bi-trash btn btn-danger btn-sm"
              onClick={() => props.onDelete(question)}
            ></button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <p>{question.description}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <p className="justify-content-start">
              ({_t("created_on")})
              <i className="bi bi-calendar-check me-1 ms-1"></i> {created_on}
            </p>
          </div>
          <div className="col-6">
            <p style={{ textAlign: 'end' }}>
              ({_t("deadline")})
              <i className="bi bi-calendar-x me-1 ms-1"></i> {deadline}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuestionCard;

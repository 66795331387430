import React, { useState } from "react";
import { Outlet }from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
//import config from "../config";
import _t from "../services/i18n.service";
import logo from "../assets/LevelUP_128_white.png";
import ScrollToTop from "react-scroll-to-top";
import config from "../config";

function Layout(props) {

  const user = props.user;
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <Navbar bg="dark" expand="lg" variant="dark" fixed="top" expanded={expanded}>
        <Container id="container">
          <Navbar.Brand href={config.BASE_PATH + "/"}>
            <img alt="" src={logo} className="d-inline-block align-top" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-5" onClick={() => setExpanded(false)}>
              
              {(user.role === "ROLE_PROFESSOR" ||
                user.role === "ROLE_STUDENT") && (
                <LinkContainer to="/">
                  <Nav.Link>
                    <i className="bi bi-easel"></i> {_t("classrooms_title")}
                  </Nav.Link>
                </LinkContainer>
              )}

              {user.role === "ROLE_STUDENT" && (
                <>
                  {/*
                  <LinkContainer to="/stu-stats">
                    <Nav.Link>
                      <i className="bi bi-clipboard-data"></i>{" "}
                      {_t("stats_title")}
                    </Nav.Link>
                  </LinkContainer>
                  */}
                  
                  <LinkContainer to="del-user">
                    <Nav.Link>
                      <i className="bi bi-trash"></i> {_t("delete_user")}
                    </Nav.Link>
                  </LinkContainer>

                  <LinkContainer to="purge-user">
                    <Nav.Link>
                    <i className="bi bi-eraser"></i> {_t("remove_user_data")}
                    </Nav.Link>
                  </LinkContainer>                  
                </>
              )}
            </Nav>
            <Nav className="ms-auto"  onClick={() => setExpanded(false)}></Nav>
            <Nav className="ms-5"  onClick={() => setExpanded(false)}>
              <NavDropdown.Divider />
              {user.email ? (
                <>
                  <LinkContainer to="lang">
                    <Nav.Link>
                      <i className="bi bi-translate"></i> {_t("link_language")}
                    </Nav.Link>
                  </LinkContainer>

                  <LinkContainer to="chgpass">
                    <Nav.Link>
                      <i className="bi bi-key"></i> {_t("change_password")}
                    </Nav.Link>
                  </LinkContainer>

                  <Nav.Link onClick={props.logOut}>
                    <i className="bi bi-box-arrow-left"></i> {_t("link_logout")}
                  </Nav.Link>
                </>
              ) : (
                <>
                  <LinkContainer to="login">
                    <Nav.Link>
                      <i className="bi bi-box-arrow-in-right"></i>{" "}
                      {_t("link_login")}
                    </Nav.Link>
                  </LinkContainer>

                  <LinkContainer to="register">
                    <Nav.Link>
                      <i className="bi bi-person-plus"></i>{" "}
                      {_t("link_register")}
                    </Nav.Link>
                  </LinkContainer>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="container-fluid mx-1 mt-5">
        <ScrollToTop smooth />
        <div className="mx-auto mt-5 row">
          <p> </p>
        </div>
        <Outlet />
        {props.children}
      </div>
    </div>
  );
}

export default Layout;

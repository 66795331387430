import React, { Component } from "react";
import _t from "../../services/i18n.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import ClassroomCard from "./classroom_card.component";
import BackendService from "../../services/backend.service";
import Classroom from "./classroom.component.js";
import Loading from "../loading.component";
import { Message, showMsg } from "../message.component";
import authService from "../../services/auth.service";
import OneSignal from 'react-onesignal';

class Student extends Component {
  constructor(props) {
    super(props);

    this.onChangeClassroom = this.onChangeClassroom.bind(this);
    this.onSelectClassroom = this.onSelectClassroom.bind(this);
    this.onJoinClassroom = this.onJoinClassroom.bind(this);
    this.goToClassroom = this.goToClassroom.bind(this);
    this.loadClassrooms = this.loadClassrooms.bind(this);
    this.setMsg = this.setMsg.bind(this);

    this.state = {
      classrooms: [],
      classroom_new_id: "",
      classroom_id: "",
      classroom: {},

      show: "loading",

      successful: false,
      message: { show: false },
    };
  }

  setMsg(message) {
    this.setState({ message: message });
  }

  loadClassrooms() {
    this.setState({
      classroom_new_id: "",
      show: "loading",
    });

    BackendService.getClassrooms()
      .then(async (res) => {
        const classrooms = res.filter((c) => c.state === "active");
        this.setState({ classrooms: classrooms, show: "default" });

        // Subscribe to classroom notifications
        const user_id = await OneSignal.getUserId();
        const user = authService.getCurrentUser();
        //console.log("user_id", user_id);

        for (let i=0; i<classrooms.length; i++) {
          await BackendService.subscribeToNotifications(classrooms[i].code.toUpperCase(), user_id);
        }

        //console.log("subscribing", user.email, user_id)
        await BackendService.subscribeToNotifications(user.email, user_id);
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.status &&
          err.response.status === 401
        ) {
          // token has expired
          document.documentElement.scrollTop = 0;
          this.setState({ show: "default" });
          showMsg(_t("session_expired"), "danger", this.setMsg);
          setTimeout(this.props.logOut, 2000);
        } else if (err.message && err.message === _t("no_notifications_warning")) {
            this.setState({ show: "default" });
            showMsg(err.message, "warning", this.setMsg);
        } else {
          this.setState({ show: "default" });
          showMsg(_t("unable_to_get_data"), "danger", this.setMsg);
        }
      });
  }

  componentDidMount() {
    this.loadClassrooms();
  }

  onChangeClassroom(e) {
    this.setState({
      classroom_new_id: e.target.value,
    });
  }

  goToClassroom(classroom) {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    this.setState({ show: "loading" });
    BackendService.getStudentQuestions(classroom.code)
      .then((res) => {
        classroom.questions = res;
        this.setState({
          classroom: classroom,
          show: "classroom",
        });
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.status &&
          err.response.status === 401
        ) {
          // token has expired
          document.documentElement.scrollTop = 0;
          this.setState({ show: "default" });
          showMsg(_t("session_expired"), "danger", this.setMsg);
          setTimeout(this.props.logOut, 2000);
        } else {
          showMsg(_t("unable_to_get_data"), "danger", this.setMsg);
          setTimeout(() => {
            this.setState({ show: "default" });
          }, 10000);
        }
      });
  }

  onSelectClassroom(e) {
    this.setState({
      classroom_id: e.target.value,
    });
  }

  onJoinClassroom(e) {
    e.preventDefault();

    const code =
      this.state.classroom_new_id.length > 0
        ? this.state.classroom_new_id
        : this.state.classroom_id;

    // check if already subscribed
    if (this.state.classrooms.find((e) => e.code === code)) {
      showMsg(_t("already_enroled"), "info", this.setMsg);
      setTimeout(() => {}, 4000);
      return;
    }

    const user = authService.getCurrentUser();

    // not subscribed, so let's subscribe!
    BackendService.subscribeToClassroom(code, user.email)
    .then(() => {
      return OneSignal.getUserId()
    })
    .then((user_id) => BackendService.subscribeToNotifications(code, user_id))
    .catch((err) => {
      //console.log("Error", err);
      if (err.message === "Classroom does not exist.") {
        err.message = _t("classroom_not_found");
      }
      if (err.message === _t("no_notifications_warning")) {
        showMsg(err.message, "warning", this.setMsg);
      }
      else {
        showMsg(err.message, "danger", this.setMsg);
      }
    })
    .finally(() => {
      this.componentDidMount();
    })
  }

  render() {
    const classrooms = this.state.classrooms;
    return (
      <>
        {this.state.message.show && (
          <Message
            text={this.state.message.text}
            type={this.state.message.type}
          />
        )}

        {this.state.show === "loading" && <Loading />}

        {this.state.show === "classroom" && (
          <Classroom
            classroom={this.state.classroom}
            goBack={() => this.componentDidMount()}
            refresh={() => this.goToClassroom(this.state.classroom)}
          />
        )}

        {this.state.show === "default" && (
          <div>
            <Form>
              <div className="form-group mt-2 row">
                <div className="col-8">
                  <Input
                    type="text"
                    className="form-control col-10"
                    placeholder={_t("new_classroom_id")}
                    name="classroom_new_id"
                    value={this.state.classroom_new_id}
                    onChange={this.onChangeClassroom}
                    maxLength="4"
                    validations={[]}
                  />
                </div>

                <div className="col-4 d-flex justify-content-end">
                  <button
                    className="btn btn-success btn-block"
                    onClick={this.onJoinClassroom}
                    
                  >
                    {_t("join")}
                  </button>
                </div>
              </div>
            </Form>

            {classrooms.length === 0 && (
              <p className="mt-2 text-center">{_t("no_enrolments")}</p>
            )}

            {classrooms.map((c, i) => (
              <ClassroomCard
                key={i}
                classroom={c}
                goToClassroom={this.goToClassroom}
              />
            ))}
          </div>
        )}
      </>
    );
  }
}

export default Student;

import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import BackendService from "../../services/backend.service";
import _t from "../../services/i18n.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        {_t("field_required")}
      </div>
    );
  }
};

class NewClassroom extends Component {
  constructor(props) {
    super(props);

    this.handleCreate = this.handleCreate.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangePhase = this.onChangePhase.bind(this);
    this.onChangeReminder = this.onChangeReminder.bind(this);

    this.state = {
      title: "",
      phase: 1,
      reminder: 1,

      successful: false,
      message: "",
    };
  }

  onChangeTitle(e) {
    this.setState({
      title: e.target.value,
    });
  }

  onChangePhase(e) {
    this.setState({
      phase: e.target.value,
    });
  }

  onChangeReminder(e) {
    this.setState({  
      reminder: e.target.value,
    });
  }

  handleCreate(e) {
    e.preventDefault();
    this.setState({
      message: "",
      successful: false,
    });
    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      BackendService.newClassroom({
        title: this.state.title,
        phase: this.state.phase,
        reminder: this.state.reminder
    }).then(
        (response) => {
          this.setState({
            message: _t("classroom_created"),
            successful: true,
          });
          setTimeout(() => this.props.onNewClassroom(response), 1000);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.setState({
            successful: false,
            message: resMessage,
          });
        }
      );
    }
  }

  render() {
    const reminder_options = window.$settings.reminder_options;

    return (
      <div>
        <div className="center-form">
          <div className="">
            <h5>{_t("new_classroom_title")}</h5>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              ref={(c) => {
                this.form = c;
              }}
            >
              {!this.state.successful && (
                <div>
                  <div className="form-group mt-2">
                    <label htmlFor="title">{_t("title")}</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="title"
                      value={this.state.title}
                      onChange={this.onChangeTitle}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group mt-2 mb-3">
                    <div className="form-check">
                      <input
                        type="radio"
                        id="phase 1"
                        name="phase"
                        value="1"
                        className="form-check-input"
                        onChange={this.onChangePhase}
                        defaultChecked
                      />
                      <label htmlFor="phase 1" className="form-check-label">
                        {_t("phase")} 1
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="radio"
                        id="phase 2"
                        name="phase"
                        value="2"
                        className="form-check-input"
                        onChange={this.onChangePhase}
                      />
                      <label htmlFor="phase 2" className="form-check-label">
                        {_t("phase")} 2
                      </label>
                    </div>
                  </div>

                  {this.state.phase === '2' && (<div className="form-group mt-2">
                    <label className="form-label">{_t("reminder")}:</label>
                    <div className="col-12 text-center">
                      <Select
                        name="reminder"
                        value={this.state.reminder}
                        className="form-select"
                        validations={[]}
                        onChange={this.onChangeReminder}
                      >
                        {Object.keys(reminder_options).map((k, i) => (
                          <option key={i} value={k} name="reminder">
                            {reminder_options[k]}
                          </option>
                        ))}
                      </Select>
                    </div>
                  </div>)}

                  <div className="form-group mt-2 row">
                    <div className="col-6 text-center">
                      <button
                        className="btn btn-secondary btn-block"
                        onClick={this.props.onCancelNewClassroom}
                      >
                        {_t("cancel")}
                      </button>
                    </div>
                    <div className="col-6 text-center">
                      <button
                        className="btn btn-success btn-block"
                        onClick={this.handleCreate}
                      >
                        {_t("create")}
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {this.state.message && (
                <div className="form-group">
                  <div
                    className={
                      this.state.successful
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {this.state.message}
                  </div>
                </div>
              )}
              <CheckButton
                style={{ display: "none" }}
                ref={(c) => {
                  this.checkBtn = c;
                }}
              />
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default NewClassroom;

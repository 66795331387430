import React from "react"

export default function Loading () {
    return (
        <div className="text-center mx-auto mt-5">
            <div className="spinner-border">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
};
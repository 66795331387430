import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import BackendService from "../services/backend.service";
import { isEmail } from "validator";
import { useNavigate } from "react-router-dom";
import _t from "../services/i18n.service";
import Language from "./language.component";
import config from "../config";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        {_t('field_required')}
      </div>
    );
  }
};

const vemail = value => {
    if (!isEmail(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          {_t('invalid_email')}
        </div>
      );
    }
  };

class ResetPassword extends Component {

  constructor(props) {
    super(props);
    this.handleReset = this.handleReset.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.state = {
      email: "",
      loading: false,
      message: ""
    };
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }


  handleReset(e) {

    const { navigate } = this.props;

    e.preventDefault();
    this.setState({
      message: "",
      loading: true
    });
   
    this.form.validateAll();     

    if (this.checkBtn.context._errors.length === 0) {
      BackendService.resetPassword(this.state.email).then(
        res => {
            this.setState({ message: _t('reset_password_msg')})
            setTimeout( () => navigate(config.BASE_PATH+"/login", {replace: true}), 4000);
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    }
  }

  render() {
    return (
      <div className="center-form">
        <div>

          <Form
            onSubmit={this.handleReset}
            ref={c => {
              this.form = c;
            }}>
            
            
            <div className="form-group">
            
            {!this.state.message && (
            <>
            <div className="form-group mt-2">
                <label htmlFor="email">Email</label>
                <Input
                type="text"
                className="form-control"
                name="email"
                value={this.state.email}
                onChange={this.onChangeEmail}
                validations={[required, vemail]}
                />
            </div>
            <div className="form-group mt-2 text-center">
              <button
                className="btn btn-success btn-block"
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span className="ms-1">{_t('reset_password')}</span>
              </button>
            </div>
            </>
            )}

            {this.state.message && (
              <div className="form-group">
                <div className="alert alert-info" role="alert">
                  {this.state.message}
                </div>
              </div>
            )}

            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
            </div>
          </Form>          
        </div>
        <Language onClick={this.props.onChangeLang}/>
      </div>
    );
  }
}

// Wrap and export
export default function(props) {
    const navigate = useNavigate();
  
    return <ResetPassword {...props} navigate={navigate} />;
}
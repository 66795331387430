import React, { useState } from "react";
import backendService from "../../services/backend.service";
import _t from "../../services/i18n.service";

export default function ClassroomCard(props) {
  let classroom = props.classroom;

  const [visible, setVisible] = useState(classroom.state === "active");
  const enabled = props.reminderStatus;

  function toggleVisible() {
    const state = props.classroom.state === "active" ? "disabled" : "active";
    props.classroom.state = state;
    backendService.updateClassroomVisibility(props.classroom.code, state)
      .then(() => { setVisible(!visible) });
  }
  
  /*
  function toggleReminder() {
    const reminder_status = props.classroom.reminder_status === 1 ? 0 : 1;
    //console.log(reminder_status);
    props.classroom.reminder_status = reminder_status;
    props.onClickActivateReminders(classroom);
  }*/

  return (
    <div className="card mt-4" onClick={() => !props.isHeader && props.goToClassroom(classroom)}>
      <div className={"card-header " + (visible ? "bg-primary text-light" : "bg-light")}>
        <div className="row">
          <div className="col-4 d-flex justify-content-start">
            <b>{classroom.code.toUpperCase()}</b>
          </div>
          <div className="col-4 d-flex justify-content-center">
            {_t("phase")} {classroom.phase}
          </div>
          <div className="col-4 d-flex justify-content-end">
            {classroom.students_count} <i className="bi bi-person-fill ms-1"></i>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-8">
            <h5 className="card-title d-flex justify-content-start">
              <span className="mx-2">
                <i className="bi bi-easel"></i>
              </span>{" "}
              {classroom.title}
            </h5>
          </div>
          {props.isHeader === true && (
            <div className="col-4 d-flex justify-content-end">
              <button
                className={visible ? "bi bi-eye btn btn-primary" : "bi bi-eye-slash btn btn-light"}
                onClick={toggleVisible}
                title={visible ? _t("classroom_active_tip") : _t("classroom_inactive_tip")}
              ></button>
              <span className="ms-2"></span>
              {props.classroom.phase === 2 && (<button
                className={enabled ? "bi bi-bell btn btn-warning" : "bi bi-bell-slash btn btn-light"}
                onClick={props.showConfirmReminders}
                title={enabled ? _t("reminders_enabled_tip") : _t("reminders_disabled_tip")}
              ></button>)}
              <span className="ms-2"></span>
              <button
                className="bi bi-trash btn btn-danger"
                onClick={props.onClickDel}
              ></button>
            </div>
          )}
          {props.isHeader === false && (
            <div className="col-4 d-flex justify-content-end">
              <i className="bi bi-chevron-right"></i>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { Component } from "react";
import _t from "../../services/i18n.service";
import BackendService from "../../services/backend.service";
import ClassroomCard from "./classroom_card.component";
import NewClassroom from "./new_classroom.component";
import Classroom from "./classroom.component";
import Loading from "../loading.component";
import { Message, showMsg } from "../message.component";

class Professor extends Component {
  constructor(props) {
    super(props);

    this.onNewClassroom = this.onNewClassroom.bind(this);
    this.onCancelNewClassroom = this.onCancelNewClassroom.bind(this);
    this.onDelClassroom = this.onDelClassroom.bind(this);
    this.goToClassroom = this.goToClassroom.bind(this);
    this.setMsg = this.setMsg.bind(this);

    this.state = {
      classrooms: [],

      show: "default",
      message: {show: false},
    };
  }

  setMsg(message) {
    this.setState({message: message});
  }

  componentDidMount() {    
    this.setState({ show: "loading" });
    BackendService.getClassrooms()
    .then((res) => {
        document.documentElement.scrollTop = 0;
        this.setState({ classrooms: res, show: "default" });        
    })
    .catch((err) => {
      if (err.response && err.response.status && err.response.status === 401) { // token has expired
        document.documentElement.scrollTop = 0;
        this.setState({show: "default"});
        showMsg(_t("session_expired"), "danger", this.setMsg);
        setTimeout(this.props.logOut, 2000);
      } else {
        //console.log(err);
        document.documentElement.scrollTop = 0;
        this.setState({show: "default"});
        showMsg(_t("unable_to_get_data"), "danger", this.setMsg);
      }
    });
  }

  onNewClassroom(classroom) {
    this.componentDidMount();
  }

  onDelClassroom(code) {
    BackendService.delClassroom(code)
      .then(() => {
        showMsg(_t('classroom_deleted'), "info", this.setMsg);
        this.componentDidMount();
      })
      .catch(() => {
        showMsg(_t("classroom_cannot_be_deleted"), "danger", this.setMsg);
      });
  }

  onNewQuestion(question) {
    BackendService.NewQuestion(question);
  }

  onCancelNewClassroom(title) {
    this.setState({ show: "default" });
  }

  goToClassroom(classroom) {
    this.setState({ show: "Classroom", classroom: classroom });
  }

  render() {
    const classrooms = this.state.classrooms;

    return (
        <>
        {this.state.message.show && (
          <Message text={this.state.message.text} type={this.state.message.type} />
        )}
        {this.state.show === "NewClassroom" && (
          <NewClassroom
            onNewClassroom={this.onNewClassroom}
            onCancelNewClassroom={this.onCancelNewClassroom}
          />
        )}
        {this.state.show === "Classroom" && (
          <Classroom
            onDelClassroom={this.onDelClassroom}
            onDelQuestion={this.onDelQuestion}
            onNewQuestion={this.onNewQuestion}
            classroom={this.state.classroom}
            logOut={this.props.logOut}
          />
        )}
        {this.state.show === "loading" && <Loading />}
        {this.state.show === "default" && (
          <div>
            <div>
              <div className="row">
                <div className="col-6">
                  <h2>{_t("classrooms_title")}</h2>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <button
                    to="new_classroom"
                    className="btn btn-success btn-block"
                    onClick={() => this.setState({ show: "NewClassroom" })}
                  >
                    <i className="bi bi-plus-circle"></i> {_t("add")}
                  </button>
                </div>
              </div>

              {classrooms.map((c, i) => (
                <ClassroomCard
                  key={i}
                  classroom={c}
                  goToClassroom={this.goToClassroom}
                  isHeader={false}
                />
              ))}
            </div>
          </div>
        )}
        </>
    );
  }
}

export default Professor;

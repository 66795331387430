import React from "react";
import _t from "../../services/i18n.service";

function ConfirmDeleteClassroom (props) {

    return (
      <div>
        <h1>{_t('confirm_delete')}</h1>
        <p>{_t('confirm_delete_classroom')} {props.classroom.title}</p>
        
        <div className="form-group mt-2 row">
            <div className="col-6 text-center">
                <button className="btn btn-secondary btn-block" onClick={() => props.onConfirm(false)}> {_t('cancel')} </button>
            </div>
            <div className="col-6 text-center">
                <button className="btn btn-success btn-block" onClick={() => props.onConfirm(true)}> {_t('ok')} </button>
            </div>                                    
        </div>
      </div>
    );
  }

  export default ConfirmDeleteClassroom;
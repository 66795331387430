import React, { useReducer, useState, useEffect } from "react";
import { createBrowserHistory } from 'history';
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import config from "./config";
//import AuthService from "./services/fake_auth.service";
import AuthService from "./services/auth.service";

import ACL from "./services/acl.service";
import BackendService from "./services/backend.service";
import Unauthorized from "./components/unauthorized.component";
import Login from "./components/login.component";
import Register from "./components/register.component";
import Language from "./components/language.component";
import _t from "./services/i18n.service";

import ResetPassword from "./components/reset_password";
import Layout from "./components/layout.component";
import Professor from "./components/professor/professor.component";
import Student from "./components/student/student.component";
import Stats from "./components/student/stats.component";
import DelUser from "./components/student/del_user.component";
import PurgeUser from "./components/student/purge_user.component";
import NotFound from "./components/notfound.component";
import ChangePassword from "./components/change_password.component";
import OneSignal from 'react-onesignal';


function App () {
   
  const [user, setUser] = useState(AuthService.getCurrentUser());
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {

    // Logs modification time
    console.log(config.VERSION); 
  
    // Update user data
    setUser(AuthService.getCurrentUser());

    // Get stored language
    const lang = window.localStorage.getItem("lang");
    window.$lang = lang ? lang : "en";

    BackendService.getSettings()
    .then(settings => {window.$settings = settings});

    // Push service UNCOMMENT AT DEPLOYMENT
    OneSignal.init({ appId: 'cca1bd1f-8df1-47e4-a28e-454eeb0b5ab3', allowLocalhostAsSecureOrigin: true });
    //OneSignal.showSlidedownPrompt();

    let isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
    !window.MSStream;

    // do not check for push notifications when in iOS
    if (!isIOS) {
      OneSignal.showNativePrompt()
    .then(() => Promise.resolve(OneSignal.isPushNotificationsEnabled()))
    .then((value) => {
      //console.log('Checking push notifications status... => ', value);
      if (!value) {
        alert(_t('enable_push_alert'));
      }
    })
    .catch(() => {
      alert(_t('enable_push_alert'));
    });
  }
  
  }, []);

  const logIn = () => {
    setUser(AuthService.getCurrentUser());
  }

  const logOut = () => {
    AuthService.logout();
    setUser(AuthService.getCurrentUser());
    window.location = config.BASE_PATH;
  }

  const onChangeLang = (lang) => {
    window.$lang = lang;
    localStorage.setItem("lang", lang);
    forceUpdate();
  }

  const delUser = (successful) => {
    if (successful) {
      logOut();
    }
  }

  const purgeUser = (successful) => {
    if (successful) {
      forceUpdate();
    }
  }

  const onChangePassword = (successful) => {
    if (successful) {
      logOut();
    }
  }

  const history = createBrowserHistory({ basename: config.BASE_PATH });

  // Render!
  return (
      <Router history={history}>
        <Routes>
          <Route path="/" element={<Layout user={user} logOut={logOut} />}>
            <Route
            index
              element={
                user.role && user.role === "ROLE_PROFESSOR" ? (
                  <Navigate to="prof" replace />
                ) : user.role && user.role === "ROLE_STUDENT" ? (
                  <Navigate to="stu" replace />
                ) : (
                  <Navigate to="login" replace />
                )
              }
            />
            <Route
              path="login"
              element={
                <Login cb={logIn} onChangeLang={onChangeLang} />
              }
            />

            <Route
              path="reset-password"
              element={
                <ResetPassword onChangeLang={onChangeLang} />
              }
            />

            <Route path="unauthorized" element={<Unauthorized />} />
            <Route
              path="register"
              element={
                <Register user={user} onChangeLang={onChangeLang} />
              }
            />
            <Route
              path="lang"
              element={<Language onClick={onChangeLang} />}
            />

            <Route
              path="prof"
              element={
                <ACL roles={["ROLE_PROFESSOR"]}>
                  <Professor user={user} logOut={logOut}/>
                </ACL>
              }
            />

            <Route
              path="stu"
              element={
                <ACL roles={["ROLE_STUDENT"]}>
                  <Student user={user} logOut={logOut}/>
                </ACL>
              }
            />

            <Route
              path="stu-stats"
              element={
                <ACL roles={["ROLE_STUDENT"]}>
                  <Stats user={user} />
                </ACL>
              }
            />

            <Route
              path="del-user"
              element={
                <ACL roles={["ROLE_STUDENT"]}>
                  <DelUser user={user} delUser={delUser} />
                </ACL>
              }
            />

            <Route
              path="purge-user"
              element={
                <ACL roles={["ROLE_STUDENT"]}>
                  <PurgeUser user={user} delUser={purgeUser} />
                </ACL>
              }
            />

            <Route
              path="chgpass"
              element={
                <ACL roles={["ROLE_STUDENT", "ROLE_PROFESSOR"]}>
                  <ChangePassword
                    user={user}
                    onChangePassword={onChangePassword}
                  />
                </ACL>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    );
}

export default App;

import React, { Component } from "react";
import Form from "react-validation/build/form";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import BackendService from "../../services/backend.service";
import _t from "../../services/i18n.service";

function addMinutes(numOfMinutes, date = new Date()) {
  // Add selected minutes to deadline date and convert to mysql format for submit
  date.setTime(date.getTime() + numOfMinutes * 60 * 1000);
  return date;
}

const required = (value) => {
  if (!value || value.length === 0) {
    return (
      <div className="alert alert-danger" role="alert">
        {_t("field_required")}
      </div>
    );
  }
};

class NewQuestion extends Component {
  constructor(props) {
    super(props);

    this.handleCreate = this.handleCreate.bind(this);
    this.onSelectQuestion = this.onSelectQuestion.bind(this);
    this.onSetPrePost = this.onSetPrePost.bind(this);
    this.onSetDeadline = this.onSetDeadline.bind(this);
    this.showPrePost = this.showPrePost.bind(this);

    // Merit time is the number of minutes a student can change the answer after submitting it.
    this.merit_time_options = window.$settings.merit_time_options;
    this.merit_time_default = 5;

    // Deadline time is the number of minutes the question is open for answers
    this.deadline_options = [5, 10, 15];
    this.deadline_default = 10;

    const first_available_question_type = props.question_types.find(
      (qt) => qt.prepost.length > 0
    );
    const id = first_available_question_type
      ? first_available_question_type.id
      : 0;
    const title = first_available_question_type
      ? first_available_question_type.question_options.title
      : "";
    const description = first_available_question_type
      ? first_available_question_type.question_options.description
      : "";
    const prepost = first_available_question_type
      ? first_available_question_type.prepost[0]
      : "";

    this.state = {
      id: id,
      title: title,
      description: description,
      deadline: this.deadline_default,
      prepost: prepost,
      merit_time: this.merit_time_default,
      showPre: this.showPrePost(title, "pre"),
      showPost: this.showPrePost(title, "post"),

      first_available_question_type: first_available_question_type,

      successful: false,
      message: "",
    };
  }

  showPrePost(title, prepost) {
    const questions = this.props.question_types.find(
      (qt) => qt.question_options.title === title
    );
    if (!questions) {
      return true;
    }
    const show = questions.prepost.find((e) => e === prepost);
    return show;
  }

  onSelectQuestion(e) {
    const question_type_id = parseInt(e.target.value);
    const question_options = this.props.question_types.find(
      (q) => q.id === question_type_id
    ).question_options;

    const new_state = {
      id: question_type_id,
      title: question_options.title,
      description: question_options.description,
      showPre: this.showPrePost(question_options.title, "pre"),
      showPost: this.showPrePost(question_options.title, "post"),
    };

    this.setState(new_state);
  }

  onSetDeadline(e) {
    this.setState({ deadline: e.target.value });
  }

  onSetMerit(e) {
    this.setState({
      merit_time: e.target.value,
    });
  }

  onSetPrePost(e) {
    this.setState({
      prepost: e.target.value,
    });
  }

  handleCreate(e) {
    e.preventDefault();
    this.setState({
      message: "",
      successful: false,
    });
    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      const answer_options = this.props.question_types.find(
        (q) => q.id === this.state.id
      ).answer_options;

      BackendService.newQuestion({
        // question info
        title: this.state.title,
        description: this.state.description,
        phase: this.props.classroom.phase,
        pre_post: this.state.prepost,
        language: window.$lang,
        ClassroomCode: this.props.classroom.code,
        deadline: addMinutes(this.state.deadline).toISOString(),
        reminder: this.props.classroom.reminder,
        merit_time: this.state.merit_time,
        options: answer_options,
      })
        .then((res) => BackendService.notifyNewQuestion(this.props.classroom))
        .then((res) => {
          this.setState({
            successful: true,
          });
          this.props.onNewQuestion(res);
        })
        .catch((error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          if (
            error.response &&
            error.response.status &&
            error.response.status === 401
          ) {
            // token has expired
            this.setState({
              successful: false,
              message: _t("session_expired"),
            });
            setTimeout(this.props.logOut, 2000);
          } else {
            this.setState({
              successful: false,
              message: resMessage,
            });
          }
        });
    }
  }

  render() {
    return (
      <div>
        <div className="center-form">
          <div className="">
            <h5>{_t("new_question_title")}</h5>
            {!this.state.first_available_question_type && (
              <p>{_t("no_question_types")}</p>
            )}
            {this.state.first_available_question_type && (
              <Form
                onSubmit={this.handleCreate}
                ref={(c) => {
                  this.form = c;
                }}
              >
                {!this.state.successful && (
                  <div>
                    <div className="form-group mt-2">
                      <div className="mb-3">
                        <label className="form-label">
                          {_t("choose_question")}:
                        </label>
                        <div className="form-check">
                          {this.props.question_types.map((q, i) => (
                            <div key={`outer_${i}`}>
                              {(this.showPrePost(
                                q.question_options.title,
                                "pre"
                              ) ||
                                this.showPrePost(
                                  q.question_options.title,
                                  "post"
                                )) && (
                                <div className="p-1" key={`div_${i}`}>
                                  <input
                                    key={i}
                                    type="radio"
                                    id={q.id}
                                    name="title"
                                    className="form-check-input"
                                    value={q.id}
                                    onChange={this.onSelectQuestion}
                                    validations={[required]}
                                    defaultChecked={
                                      q.id ===
                                      this.state.first_available_question_type
                                        .id
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    key={`label_${i}`}
                                  >
                                    {" "}
                                    {q.question_options.title}
                                  </label>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="form-group mt-2 mb-3">
                      <label
                        htmlFor="description"
                        className="form-check-label mb-1"
                      >
                        {_t("description")}:
                      </label>
                      <p className="text-secondary">
                        <i>{this.state.description}</i>
                      </p>
                    </div>

                    <div className="form-group mt-2">
                      <label className="form-label">
                        {_t("deadline")} ({_t("minutes")}):
                      </label>
                      <div className="col-12 text-center">
                        <Select
                          name="deadline"
                          className="form-select"
                          onChange={this.onSetDeadline}
                          value={this.state.deadline}
                        >
                          {Object.keys(this.deadline_options).map((k, i) => (
                            <option key={i} value={this.deadline_options[k]}>
                              {this.deadline_options[k]}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </div>

                    <div className="form-group mt-2 mb-3">
                      {this.state.showPre && (
                        <div className="form-check">
                          <input
                            type="radio"
                            id="pre"
                            name="prepost"
                            value="pre"
                            className="form-check-input"
                            defaultChecked
                            onChange={this.onSetPrePost}
                          />
                          <label htmlFor="pre" className="form-check-label">
                            Pre
                          </label>
                        </div>
                      )}
                      {this.state.showPost && (
                        <div className="form-check">
                          <input
                            type="radio"
                            id="post"
                            name="prepost"
                            value="post"
                            className="form-check-input"
                            onChange={this.onSetPrePost}
                          />
                          <label htmlFor="post" className="form-check-label">
                            Post
                          </label>
                        </div>
                      )}
                    </div>

                    <div className="form-group mt-2">
                      <label className="form-label">{_t("merit_time")}:</label>
                      <div className="col-12 text-center">
                        <Select
                          name="merit_time"
                          value={this.merit_time_default}
                          className="form-select"
                          validations={[required]}
                          onChange={this.onSetMeritTime}
                          defaultValue={this.merit_time_default}
                        >
                          {
                            //<option value="0">{_t("none")}</option>
                          }
                          {Object.keys(this.merit_time_options).map((k, i) => (
                            <option
                              key={i}
                              value={this.merit_time_options[k]}
                            >
                              {this.merit_time_options[k]}
                            </option>
                          ))}
                        </Select>
                        
                      </div>
                    </div>

                    {this.state.message && (
                      <div className="form-group">
                        <div
                          className={
                            this.state.successful
                              ? "alert alert-success"
                              : "alert alert-danger"
                          }
                          role="alert"
                        >
                          {this.state.message}
                        </div>
                      </div>
                    )}

                    <div className="form-group mt-5 row">
                      <div className="col-6 text-center">
                        <button
                          className="btn btn-secondary btn-block"
                          onClick={this.props.onCancelNewQuestion}
                        >
                          {_t("cancel")}
                        </button>
                      </div>
                      <div className="col-6 text-center">
                        <button
                          className="btn btn-success btn-block"
                          onClick={this.handleCreate}
                        >
                          {_t("create")}
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                <CheckButton
                  style={{ display: "none" }}
                  ref={(c) => {
                    this.checkBtn = c;
                  }}
                />
              </Form>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default NewQuestion;

import React from "react"
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Cell, Tooltip } from 'recharts';
//import _t from "../../services/i18n.service"


function Stats (props) {
  
  const data = [
    {
      name: 'pre',
      answer: props.response.pre || 0,
    },
    {
      name: 'post',
      answer: props.response.post || 0,
    },
  ];

  return (

    <BarChart
      width={400}
      height={200}
      data={data} 
      margin={{
        top: 5,
        right: 5,
        left: 5,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis domain={[0, 10]}/>
      <Tooltip />
      {/*<Legend />*/}
      <Bar dataKey="answer">
        <Cell key="cell-0" fill="#94e4c9" />
        <Cell key="cell-1" fill="#509b7d" />
      </Bar>
    </BarChart>

  );
}

export default Stats;
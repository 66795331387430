import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { useNavigate } from "react-router-dom";
import BackendService from "../services/backend.service";
import _t from "../services/i18n.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        {_t("field_required")}
      </div>
    );
  }
};

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangePass = this.onChangePass.bind(this);
    this.onChangeNewPass = this.onChangeNewPass.bind(this);    

    this.state = {
      pass: "",
      new_pass: "",

      successful: false,
      message: "",
    };
  }

  onChangePass(e) {
    this.setState({
      pass: e.target.value,
    });
  }

  onChangeNewPass(e) {
    this.setState({
      new_pass: e.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      message: "",
      successful: false,
    });
    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      BackendService.changePassword(
        this.props.user.email,
        this.state.pass,
        this.state.new_pass,
      ).then(
        (response) => {
          this.setState({
            message: response.message,
            successful: true,
          });
          setTimeout(() => this.props.onChangePassword(true), 1000);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.setState({
            successful: false,
            message: resMessage,
          });
        }
      );
    }
  }

  render() {
    return (
      <div>
        <div className="center-form">
          <div className="">
            <h5>{_t("change_password")}</h5>
            <Form
              onSubmit={this.handleSubmit}
              ref={(c) => {
                this.form = c;
              }}
            >
              {!this.state.successful && (
                <div>
                  <div className="form-group mt-2">
                    <label htmlFor="password">{_t("enter_curr_password")}</label>
                    <Input
                      type="password"
                      className="form-control"
                      name="password"
                      value={this.state.pass}
                      onChange={this.onChangePass}
                      validations={[required]}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="password">{_t("enter_new_password")}</label>
                    <Input
                      type="password"
                      className="form-control"
                      name="new_password"
                      value={this.state.new_pass}
                      onChange={this.onChangeNewPass}
                      validations={[required]}
                    />
                  </div>                  
                  <div className="form-group mt-2 row">
                    <div className="col-6 text-center">
                      <button
                        className="btn btn-secondary btn-block"
                        onClick={() => this.props.navigate(-1)}
                      >
                        {_t("cancel")}
                      </button>
                    </div>
                    <div className="col-6 text-center">
                      <button
                        className="btn btn-success btn-block"
                        onClick={this.handleSubmit}
                      >
                        {_t("submit")}
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {this.state.message && (
                <div className="form-group">
                  <div
                    className={
                      this.state.successful
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {this.state.message}
                  </div>
                </div>
              )}
              <CheckButton
                style={{ display: "none" }}
                ref={(c) => {
                  this.checkBtn = c;
                }}
              />
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

// Wrap and export
export default function(props) {
  const navigate = useNavigate();

  return <ChangePassword {...props} navigate={navigate} />;
}

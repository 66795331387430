export function toLocalTime (timeStr) {
    return new Date(timeStr + ":00.000Z");
}

export function toServerTime(dateStr) {
    // convert date string to a time stamp at the end of the day according to the local time zone
    return (new Date(dateStr+"T23:59")).toISOString().slice(0,16);
};

export function toLocalTimeString (date) {
    return date.toLocaleString({}, {dateStyle: "short", timeStyle: "short"});
}
import React from 'react';

function Language(props) {

    let languages = [
        { lang: 'English', code: 'en' },
        { lang: 'Español', code: 'es' },
        { lang: 'Ελληνικά', code: 'gr' },
        { lang: 'Dutch', code: 'de' },
    ];

    return (
        <div className="btn-group text-center col-12 mt-3" role="group">
            {languages.map((l) => (
                <button style={{ fontWeight: l.code === window.$lang ? 'bold' : 'lighter', textDecoration: 'none' }}
                    className="col-4 btn btn-link"
                    type="button"
                    key={l.code}
                    onClick={() => props.onClick(l.code)}
                >
                    {l.lang}
                </button>
            ))}
        </div>
    );
}

export default Language;
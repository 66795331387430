import React from "react";
import _t from "../../services/i18n.service";

function ConfirmActivateReminders (props) {  
    const frequency = String(props.classroom.reminder);
    return (
      <div>
        {props.reminder_status === 0 ?         
          (<>
            <h1>{_t('confirm_activation')}</h1>
            <p>{_t('confirm_activate_reminders').replace('__frequency__', frequency)} </p>
           </>
          ) 
          :
          (<>
            <h1>{_t('confirm_deactivation')}</h1>
            <p>{_t('confirm_deactivate_reminders')} </p>
            </>
          ) 
        }
        <div className="form-group mt-2 row">
            <div className="col-6 text-center">
                <button className="btn btn-secondary btn-block" onClick={() => props.onConfirm(false)}> {_t('cancel')} </button>
            </div>
            <div className="col-6 text-center">
                <button className="btn btn-success btn-block" onClick={() => props.onConfirm(true)}> {_t('ok')} </button>
            </div>
        </div>
      </div>
    );
  }

  export default ConfirmActivateReminders;
import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { useNavigate } from "react-router-dom";
import BackendService from "../../services/backend.service";
import OneSignal from 'react-onesignal';
import _t from "../../services/i18n.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        {_t("field_required")}
      </div>
    );
  }
};

class PurgeUser extends Component {
  constructor(props) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
    this.onChangePass = this.onChangePass.bind(this);

    this.state = {
      pass: "",

      successful: false,
      message: "",
    };
  }

  onChangePass(e) {
    this.setState({
      pass: e.target.value,
    });
  }

  handleDelete(e) {
    e.preventDefault();
    this.setState({
      message: "",
      successful: false,
    });
    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      BackendService.purgeUser(this.props.user.email, this.state.pass)
      .then(async () => {
        var user_id = null;
        user_id = await OneSignal.getUserId();
        const classrooms = await BackendService.getClassrooms();
        return ([classrooms, user_id]);
      })
      .then(async ([classrooms, user_id]) => {
        if (user_id) {
          for (let i=0; i<classrooms.length; i++) {
            await BackendService.unsubscribeFromNotifications(classrooms[i].code.toUpperCase(), user_id);
          }
        }
      })
      .then(() => {
        this.setState({
          message: _t("user_purged"),
          successful: true,
        });
        setTimeout(() => this.props.delUser(true), 1000);
      })
      .catch(
        (err) => {
          console.log(err);
          this.setState({
              successful: false,
              message: (err && err.message) || _t('operation_failed'),
          });
        }
      );
    }
  }

  render() {
    return (
      <div>
        <div className="center-form">
          <div className="">
            <h5>{_t("purge_user_instructions")}</h5>
            <Form
              onSubmit={this.handleDelete}
              ref={(c) => {
                this.form = c;
              }}
            >
              {!this.state.successful && (
                <div>
                  <div className="form-group mt-2">
                    <label htmlFor="password">{_t("enter_password")}</label>
                    <Input
                      type="password"
                      className="form-control"
                      name="password"
                      value={this.state.pass}
                      onChange={this.onChangePass}
                      validations={[required]}
                    />
                  </div>
                  <div className="form-group mt-2 row">
                    <div className="col-6 text-center">
                      <button
                        className="btn btn-secondary btn-block"
                        onClick={() => this.props.navigate(-1)}
                      >
                        {_t("cancel")}
                      </button>
                    </div>
                    <div className="col-6 text-center">
                      <button
                        className="btn btn-danger btn-block"
                        onClick={this.handleDelete}
                      >
                        {_t("delete")}
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {this.state.message && (
                <div className="form-group">
                  <div
                    className={
                      this.state.successful
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {this.state.message}
                  </div>
                </div>
              )}
              <CheckButton
                style={{ display: "none" }}
                ref={(c) => {
                  this.checkBtn = c;
                }}
              />
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

// Wrap and export
export default function(props) {
  const navigate = useNavigate();

  return <PurgeUser {...props} navigate={navigate} />;
}

import React from "react";
import _t from "../../services/i18n.service";
import Stats from "./stats.component";
//import { toLocalTimeString } from "../../services/time.service";

function QuestionHistory(props) {
  let question = props.question;

  return (
    <div className="card row mt-4 mx-4">
      <div
        className={
          "card-header " +
          (question.pre_answer && question.post_answer
            ? "bg-primary text-light"
            : "bg-warning"
          )
        }
      >
        <div className="row">
          <div className="col-7">{question.title}</div>
          <div className="col-5 d-flex justify-content-end">
            {question.pre_answer && question.post_answer  ? 
              <>{_t('answered')} <i className="bi bi-check2-circle ms-1"></i></>
              :
              <>{_t('pending')} <i className="bi bi-hourglass-split ms-1"></i></>
            }
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 text-secondary">
            <p>{question.description}</p>
          </div>
        </div>
        
        <Stats response={question.response}/>
      </div>
    </div>
  );
}

export default QuestionHistory;

import React, { useEffect, useState } from "react";
import _t from "../../services/i18n.service";
import ClassroomCard from "./classroom_card.component";
import QuestionCard from "./question_card.component";
import ConfirmDeleteClassroom from "./confirm_delete_classroom.component";
import ConfirmDeleteQuestion from "./confirm_delete_question.component";
import ConfirmActivateReminders from "./confirm_activate_reminders";
import BackendService from "../../services/backend.service";
import NewQuestion from "./new_question.component";
import Loading from "../loading.component";
import { Message, showMsg } from "../message.component";

function Classroom(props) {
  
  const [questions, setQuestions] = useState([]);
  const [show, setShow] = useState("loading");
  const [question, setQuestion] = useState();
  const [message, setMessage] = useState({ text: "", type: "", show: false });
  const [reminderStatus, setReminderStatus] = useState(props.classroom.reminder_status)

  const loadQuestions = () => {
    setShow("loading");
    BackendService.getProfessorQuestions(props.classroom.code)
      .then((res) => {
        setQuestions(res);
        setShow("default");
      })
      .catch(() => {
        showMsg(_t("unable_to_get_data"), "danger", setMessage);
      });
  };

  // scroll to top after populating questions
  useEffect(() => {
    setShow("default");
  }, [questions]);

  useEffect(() => {
    loadQuestions();
  }, []);

  const onDelClassroom = () => {
    props.onDelClassroom(props.classroom.code);
  };
  const onActivateReminders = () => {
    if (props.classroom.students_count === 0) {
      showMsg(_t('no_reminder_without_enrolments'), "danger", setMessage);
      setShow("default");
    } else {
      BackendService.subscriptionsExist(props.classroom)
      .then(() => BackendService.updateClassroomReminderStatus(props.classroom, reminderStatus ? 0 : 1))
      .then(() => { 
        if (!reminderStatus) {
          showMsg(_t('reminders_activated'), "info", setMessage);
        } else {
          showMsg(_t('reminders_disabled'), "info", setMessage);
        }
        setReminderStatus(reminderStatus ? 0 : 1);
        setShow("default");
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          showMsg(_t('no_reminder_without_enrolments'), "danger", setMessage);
        }
        else {
          showMsg(_t('operation_failed'), "danger", setMessage);
        }
        setShow("default");
      });
    }
  };

  const confirmDelQuestion = (question) => {
    setShow("confirmQuestion");
    setQuestion(question);
  };

  const onDelQuestion = () => {
    BackendService.delQuestion(question.Question_id)
      .then(() => {
        showMsg(_t("question_deleted"), "info", setMessage);
        setShow("");
        setTimeout(loadQuestions, 2000);
      })
      .catch(() => {
        showMsg(_t("operation_failed"), "danger", setMessage);
      });
  };

  const onNewQuestion = () => {
    showMsg(_t("question_created"), "success", setMessage);
    setTimeout(loadQuestions, 2000);
  };

  // Return those question types available 
  // In the same classroom, two questions cannot have same title/prepost combination of values
  const questionTypes = (questions) => {

    // Get all possible questions according to phase
    let question_types = window.$settings.question_types.filter(
      (q) => q.question_options.phase === props.classroom.phase
    );

    // Add a prepost field with avaible states
    question_types = question_types.map(qt => {
      const used = questions.filter(q => qt.question_options.title === q.title).map(q => q.pre_post);
      let available = [];
      used.find(e => e === "pre") || available.push("pre")
      used.find(e => e === "post") || available.push("post")
      qt.prepost = available;
      return qt;
    });

    return question_types;
  }

  window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  return (
    <div>
      {message.show && <Message text={message.text} type={message.type} />}
      {show === "confirmReminders" && (
        <ConfirmActivateReminders
          classroom={props.classroom}
          reminder_status = {reminderStatus}
          onConfirm={(a) => (a ? onActivateReminders() : setShow("default"))}
        />
      )}
      {show === "confirmClassroom" && (
        <ConfirmDeleteClassroom
          classroom={props.classroom}
          onConfirm={(a) => (a ? onDelClassroom() : setShow("default"))}
        />
      )}
      {show === "confirmQuestion" && (
        <ConfirmDeleteQuestion
          question={question}
          onConfirm={(a) => (a ? onDelQuestion() : loadQuestions())}
        />
      )}
      {show === "loading" && <Loading />}
      {show === "newQuestion" && (
        <NewQuestion
          onNewQuestion={() => onNewQuestion()}
          onCancelNewQuestion={() => setShow("default")}
          question_types={questionTypes(questions)}
          classroom={props.classroom}
          logOut={props.logOut}
        />
      )}
      {show === "default" && (
        <div>
          <ClassroomCard classroom={props.classroom} 
            reminderStatus={reminderStatus}
            isHeader={true} 
            onClickDel={() => setShow("confirmClassroom")} 
            showConfirmReminders={() => setShow("confirmReminders")}/>

          <div className="row mt-5">
            <div className="col-6">
              <h5>{_t("questions")}</h5>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <button
                className="btn btn-success btn-block"
                onClick={() => setShow("newQuestion")}
              >
                <i className="bi bi-plus-circle"></i> {_t("add")}
              </button>
            </div>
          </div>
          {questions.length === 0 && 
                (
                    <p>{_t("no_questions")}</p>
          )}
          {questions.map((q, i) => (
            <QuestionCard key={i} question={q} onDelete={confirmDelQuestion} />
          ))}
        </div>
      )}
    </div>
  );
}

export default Classroom;

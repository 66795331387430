import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import AuthService from "../services/auth.service";
import _t from "../services/i18n.service";
import { useNavigate } from "react-router-dom";
import Language from "./language.component";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        {_t("field_required")}
      </div>
    );
  }
};

const vemail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        {_t("invalid_email")}
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        {_t("password_instructions")}
      </div>
    );
  }
};

function RegisterOk() {
  let navigate = useNavigate();

  return (
    <div>
      <h1>{_t("new_account_title")}</h1>
      <p>{_t("user_registered")}</p>
      <button
        className="btn btn-primary btn-block"
        onClick={() => navigate("/login")}
      >
        {_t("close")}
      </button>
    </div>
  );
}

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeRole = this.onChangeRole.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.state = {
      email: "",
      password: "",
      successful: false,
      message: "",
      role: "ROLE_STUDENT",
    };
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  onChangeRole(e) {
    this.setState({
      role: e.target.value,
    });
  }

  handleRegister(e) {
    e.preventDefault();
    this.setState({
      message: "",
      successful: false,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.register(
        this.state.email,
        this.state.password,
        this.state.role
      ).then(
        (res) => {
          this.setState({
            message: _t('user_registered'),
            successful: true,
          });
      })
      .catch(
        (err) => {
          //console.log(err);
          const err_msg = err.response.data.message;
          if (err_msg === 'Email address already exists.') {
              this.setState({
                message: _t('email_already_exists'),
                successful: false,
              });
          } else {
            this.setState({
              message: err_msg,
              successful: false,
            });
          }
      });
    }
  }

  render() {
    return (
      <div>
        {!this.state.successful ? (
          <div className="center-form">
            <div className="">
              <Form
                onSubmit={this.handleRegister}
                ref={(c) => {
                  this.form = c;
                }}
              >
                {!this.state.successful && (
                  <div>
                    <div className="form-group mt-2">
                      <label htmlFor="email">{_t("enter_email")}</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="email"
                        value={this.state.email}
                        onChange={this.onChangeEmail}
                        validations={[required, vemail]}
                      />
                    </div>
                    <div className="form-group mt-2">
                      <label htmlFor="password">{_t("enter_password")}</label>
                      <Input
                        type="password"
                        className="form-control"
                        name="password"
                        value={this.state.password}
                        onChange={this.onChangePassword}
                        validations={[required, vpassword]}
                      />
                    </div>
                    <div className="form-group mt-2 row">
                      <label htmlFor="student" className="col-6 text-center">
                        {_t("student")}
                      </label>
                      <label htmlFor="professor" className="col-6 text-center">
                        {_t("professor")}
                      </label>
                    </div>
                    <div className="row">
                      <input
                        type="radio"
                        id="student"
                        className="col-6"
                        name="role"
                        value="ROLE_STUDENT"
                        defaultChecked
                        onChange={this.onChangeRole}
                      />

                      <input
                        type="radio"
                        id="professor"
                        className="col-6"
                        name="role"
                        value="ROLE_PROFESSOR"
                        onChange={this.onChangeRole}
                      />
                    </div>
                    <div className="form-group mt-2 text-center">
                      <button className="btn btn-success btn-block">
                        {_t("link_register")}
                      </button>
                    </div>
                  </div>
                )}
                {this.state.message && (
                  <div className="form-group">
                    <div
                      className={
                        this.state.successful
                          ? "alert alert-success"
                          : "alert alert-danger"
                      }
                      role="alert"
                    >
                      {this.state.message}
                    </div>
                  </div>
                )}
                <CheckButton
                  style={{ display: "none" }}
                  ref={(c) => {
                    this.checkBtn = c;
                  }}
                />
              </Form>
            </div>
            <Language onClick={this.props.onChangeLang} />
          </div>
        ) : (
          <RegisterOk />
        )}
      </div>
    );
  }
}
